import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Navbar from "./navbar";
import Footer from "./footer";
export default class Privacy_Policy extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Navbar />
        <MDBContainer>
          <div className="">
            <h2 className="contact_heading">Privacy Policy</h2>
          </div>
          <MDBRow>
            <h4>What information do we collect?</h4>
            <p>
              {" "}
              We collect information from you when you fill out a form. When
              ordering or registering on our site, as appropriate, you may be
              asked to enter your: name, e-mail address, mailing address, phone
              number or credit card information. You may, however, visit our
              site anonymously.{" "}
            </p>
            <h4>What do we use your information for?</h4>
            <p>
              {" "}
              Any of the information we collect from you may be used in one of
              the following ways: To personalize your experience Your
              information helps us to better respond to your individual needs To
              improve our website We continually strive to improve our website
              offerings based on the information and feedback we receive from
              you To improve customer service Your information helps us to more
              effectively respond to your customer service requests and support
              needs To process transactions Your information, whether public or
              private, will not be sold, exchanged, transferred, or given to any
              other company for any reason whatsoever, without your consent,
              other than for the express purpose of delivering the purchased
              product or service requested. To administer a contest, promotion,
              survey or other site feature To send periodic emails The email
              address you provide for order processing, may be used to send you
              information and updates pertaining to your order, in addition to
              receiving occasional company news, updates, related product or
              service information, etc. How do we protect your information? We
              implement a variety of security measures to maintain the safety of
              your personal information when you place an order or enter,
              submit, or access your personal information.{" "}
            </p>
            <h4>Do we use cookies?</h4>
            <p>
              {" "}
              Yes (Cookies are small files that a site or its service provider
              transfers to your computers hard drive through your Web browser
              (if you allow) that enables the sites or service providers systems
              to recognize your browser and capture and remember certain
              information We use cookies to understand and save your preferences
              for future visits and compile aggregate data about site traffic
              and site interaction so that we can offer better site experiences
              and tools in the future. We may contract with third-party service
              providers to assist us in better understanding our site visitors.
              These service providers are not permitted to use the information
              collected on our behalf except to help us conduct and improve our
              business.{" "}
            </p>
            <h4>Do we disclose any information to outside parties?</h4>
            <p>
              {" "}
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information. This does not include
              trusted third parties who assist us in operating our website,
              conducting our business, or servicing you, so long as those
              parties agree to keep this information confidential. We may also
              release your information when we believe release is appropriate to
              comply with the law, enforce our site policies, or protect ours or
              others rights, property, or safety. However, non-personally
              identifiable visitor information may be provided to other parties
              for marketing, advertising, or other uses.{" "}
            </p>
            <h4>Third-party links </h4>
            <p>
              Occasionally, at our discretion, we may include or offer
              third-party products or services on our website. These third-party
              sites have separate and independent privacy policies. We,
              therefore, have no responsibility or liability for the content and
              activities of these linked sites. Nonetheless, we seek to protect
              the integrity of our site and welcome any feedback about these
              sites.{" "}
            </p>
            <h4>California Online Privacy Protection Act Compliance</h4>
            <p>
              {" "}
              Because we value your privacy we have taken the necessary
              precautions to be in compliance with the California Online Privacy
              Protection Act. We, therefore, will not distribute your personal
              information to outside parties without your consent.{" "}
            </p>
            <h4>Children's Online Privacy Protection Act Compliance</h4>
            <p>
              {" "}
              We are in compliance with the requirements of COPPA (Children's
              Online Privacy Protection Act), we do not collect any information
              from anyone under 13 years of age. Our website, products, and
              services are all directed to people who are at least 13 years old
              or older.
            </p>
            <h4 style={{ width: "100%" }}>Online Privacy Policy Only</h4>
            <p>
              {" "}
              This online privacy policy applies only to information collected
              through our website and not to information collected offline.
            </p>
            <br></br>
            <h4 style={{ width: "100%" }}> Terms and Conditions</h4>
            <p>
              {" "}
              Please also visit our Terms and Conditions section establishing
              the use, disclaimers, and limitations of liability governing the
              use of our website at https://www.DigiMonk.in{" "}
            </p>
            <br></br>
            <h4 style={{ width: "100%" }}>Your Consent</h4>
            <p>
              {" "}
              By using our site, you consent to our online privacy policy.{" "}
            </p>
            <br></br>
            <h4 style={{ width: "100%" }}>Changes to our Privacy Policy</h4>
            <p>
              {" "}
              If we decide to change our privacy policy, we will post those
              changes on this page.{" "}
            </p>
            <br></br>
            <h4 style={{ width: "100%" }}>Contacting Us </h4>
            <p>
              {" "}
              If there are any questions regarding this privacy policy you may
              contact us using the information below.
            </p>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </>
    );
  }
}
