import Axios from "axios";
import { API } from "../../config";

export const add_notification = (data) => {
  return Axios.post(`${API}/admin/account_api/add_notification_settings`, data);
};

// export const add_notification = (data) => {
//   return Axios.post(
//     `https://digimonk.net/dashify-ci/admin/account_api/add_notification_settings`, data );
// };

export const delete_email = (data) => {
  return Axios.post(
    `${API}/admin/account_api/delete_notification_emailid`,
    data
  );
};

export const get_notification = (data) => {
  return Axios.post(`${API}/admin/account_api/get_notification_settings`, data);
};

export const fetchAll_notification = (data) => {
  return Axios.post(`${API}/admin/location_api/get_all_notification`, data);
};

export const Update_Notification = (data) => {
  return Axios.post(
    `${API}/admin/account_api/update_notification_settings`,
    data
  );
};

export const AddEmail_Bylocation = (data) => {
  return Axios.post(
    `${API}/admin/location_api/add_settings_view_location`,
    data
  );
};

export const DeleteEmail_Bylocation = (data) => {
  return Axios.post(
    `${API}/admin/location_api/delete_view_location_emailid`,
    data
  );
};

export const ListEmail_Bylocation = (data) => {
  return Axios.post(
    `${API}/admin/location_api/email_list_settings_view_location`,
    data
  );
};
