import React from "react";
import axios from "axios";
import Moment from "react-moment";
import { Container } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

class BlogHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
      baseurl: [],
      datearray: [],
    };
  }

  componentDidMount() {
    const data = {
      secure_pin: "digimonk",
    };
    try {
      axios
        .post("https://dashify.biz/Api/admin/blog_api/get_blogs_home", data)
        .then((response) => {
          this.setState({
            apiData: response.data.blogData,
            apiData2: response.data.blogData,
            baseurl: response.data.baseurl,
          });
        });
    } catch (e) {
      console.log(e);
    }
  }
  handleClick(date) {
    var final = this.state.apiData2.filter((r) => r.filter_date === date);
    this.setState({ apiData: final });
  }

  render() {
    // console.log('mom',<Moment format="MMM YYYY">2021-06-09</Moment>)

    let FinalRender;

    let FinalDate;

    // var moment = require('moment')
    // var today = moment().format('D/MMMM/Y')
    var mydata = [];
    var FinalDate1;
    if (this.state.apiData2) {
      FinalDate = this.state.apiData2.map((r) => {
        mydata.push(r.filter_date);
        return (
          <span>
            <Moment format="MMM YYYY">{r.filter_date}</Moment>
          </span>
        );
      });
    }
    const unique = [...new Set(mydata)];
    if (unique) {
      FinalDate1 = unique.map((r) => {
        return (
          <>
            <span onClick={this.handleClick.bind(this, r)}>
              <Moment format="MMM YYYY">{r}</Moment>
            </span>
          </>
        );
      });
    }
    if (this.state.apiData2) {
      FinalRender = this.state.apiData.map((r) => {
        return (
          <div className="col-md-6">
            <Link to={`blog-detail/${r.id}`}>
              <div className="feed-box">
                <div className="feed-img">
                  {r.image_file != "" ? (
                    <img src={this.state.baseurl + r.image_file} alt="" />
                  ) : (
                    ""
                  )}
                </div>
                <div className="feed-text">
                  <div className="webox">
                    <p>{r.description}</p>
                    <p>{r.name}</p>
                    <span>
                      <Moment format="MMM YYYY">{r.filter_date}</Moment>
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      });
    } else {
      return <div class="row">Data Not Found</div>;
    }
    return (
      <div>
        <Container>
          <h1 className="text-center">Blogs</h1>
          <div className="text-center dateformat">{FinalDate1}</div>
        </Container>

        {/* {mydata}
        {FinalDate} */}
        <div className="">
          <div className="container">
            <div className="row top-50">{FinalRender}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default BlogHome;
