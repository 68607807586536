import Axios from "axios";
import { API } from "../../config";

export const Add_Keyword = (data) => {
  return Axios.post(`${API}/admin/location_api/add_keyword`, data);
};

export const Get_Keywords = (data) => {
  return Axios.post(
    `${API}/admin/location_api/get_keyword_ranking_analytics`,
    data
  );
};
