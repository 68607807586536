import React, { Component } from 'react'
import { MDBRow, MDBCol,  MDBContainer, MDBBtn } from 'mdbreact';
import review_img1 from './assets/review_img1.png'
import review_img2 from './assets/review_img2.png'
import clock from './assets/clock.png'
import { fetchAll_notification } from './apis/notification';
import { secure_pin } from '../config';
import moment from 'moment';

 
export default class Activity extends Component {
  state={
    AllNot:[]
  }

  componentDidMount(){
    console.log("date",moment().format("YYYY-MM-DD"))
    var today=moment().format("YYYY-MM-DD");
    const data={"secure_pin":secure_pin,"filter_date":today}
    fetchAll_notification(data).then(res=>{
      console.log(res);
      this.setState({AllNot:res.data.notificationData})
    })
    .catch(res=>{
      console.log(res)
    })
  }
    render() {
let {AllNot} =this.state;
var final=[];
console.log(this.state,final)
      if (AllNot){
        final=AllNot.map(r=>{
          var date_split=r.cdate.split(' ')
          const months = ["JAN", "FEB", "MAR","APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
          let current_datetime = new Date(date_split[0])
          let formatted_date = current_datetime.getDate() + " " + months[current_datetime.getMonth()] + " " + current_datetime.getFullYear()
          console.log("778",formatted_date)
          // this.setState({LastSyncDate:formatted_date,
          //   LastSyncTime:date_split[1]})

          return( <MDBRow  className='review_container'>
          <MDBCol md="2">
          <img src={r.url} alt='review_icon' className='review_img' />
          </MDBCol>
          <MDBCol md="7">
            <div >
                <span className='review_heading2'>{r.name?r.name:"User"}</span>
            <span className='notification_contant1'>New Review on {r.connect_type}</span>
            </div>
            
            <div className='review_contant3'>
           {r.text}
            </div>
            {/* <div><MDBBtn className='btn_notification'>Joined Moderator</MDBBtn></div> */}
          </MDBCol>
          <MDBCol md="3" >
              <span><img src={clock} alt='review_icon' /></span>
             <span className='review_contant3' style={{marginLeft:'2%'}}>{formatted_date} at {date_split[1]?date_split[1]:""} </span>
          </MDBCol>
        </MDBRow>)
        })
      }
        return ( 
            <div>          
                <MDBContainer>
                <div className='setting-10'>
                            <h3>Activity</h3>
                            </div>
                        {this.state.AllNot.length>0?final:
                        
                        <div className="main_content">
                        {/* <div className="rightside_title">
                          <h1>Review Generation Stats </h1>
                        </div> */}
                        <div className="mb-30">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="analytics-whice mt-30">
                               
                                <div className="promotional-box text-center selectedbx">
                                  <img src={require("../images/campain-img.jpg")} />
                                  <h2>Sorry!</h2>
                                  <p>
                                    No Activity Available
                                
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        }
                                </MDBContainer>
            </div>
        )
    }
}
