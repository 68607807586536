import React, { Component } from "react";
import Axios from "axios";
import { logout } from "./apis/user";
import { all_location } from "./apis/location";
import { all_connection_of_one_location } from "./apis/social_platforms";
import { Link } from "react-router-dom";
import ViewLocations from "./location-manager";
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from "mdbreact";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Loader from "react-loader-spinner";
import { get_login_user_info } from "./apis/user";
import { secure_pin } from "../config";
import SelectSearch from "react-select-search";

let location_id = localStorage.getItem("locationId");

export default class Topbarmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      user_image: "",
      loading_info: true,

      AllLocations: [],
      changev: false,
      locationid: "",
      defa: "",
      fb_notification: "",
      fbReviews: [],
      googleReviews: [],
      citysearchReviews: [],
      yelpReviews: [],
      view_notification_type1: false,
      search: [],
      role: "",
      packageData: "",
    };
    this.change = this.change.bind(this);
  }

  change = (id, name) => (e) => {
    try {
      localStorage.setItem("locationId", id);
      localStorage.setItem("locationName", name);

      window.location.assign("dashboard#/locations/" + id + "/view-location");
      window.location.reload(false);
    } catch (e) {}
  };

  logout = () => {
    try {
      localStorage.clear();

      logout()
        .then((res) => {
          console.log("sucess");
          console.log(res);
        })
        .catch((res) => {
          console.log("error in Logout");
        });
    } catch (e) {}
  };

  componentDidMount() {
    try {
      this.setState({ role: this.props.role });

      const data = {
        user_id: localStorage.getItem("UserId"),

        secure_pin,
      };

      // for getting user details
      let data3 = { user_id: localStorage.getItem("UserId"), secure_pin };
      get_login_user_info(data3)
        .then((res) => {
          console.log("user info", res.data);
          if (res.data && res.data.users_login) {
            this.setState({
              first_name: res.data.users_login[0].first_name,
              last_name: res.data.users_login[0].last_name,
              user_image: res.data.users_login[0].profile_image,
              loading_info: false,
              packageData: res.data.packageData[0].name,
            });
            console.log(this.state.packageData, "package check ");
          } else {
            this.setState({ loading_info: false });
          }
        })
        .catch((err) => {
          console.log("user info err", err);
          this.setState({ loading_info: false });
        });

      // for notifications
      all_location(data)
        .then((res) => {
          this.setState({ AllLocations: res.data.all_location });
        })
        .catch((res) => {
          console.log("error in LocationManager");
        });
    } catch (e) {}
  }
  componentDidUpdate() {
    try {
      if (this.state.role !== this.props.role)
        this.setState({ role: this.props.role });
    } catch (e) {}
  }
  onKeyDown(e) {
    try {
      var input = "";
      if (e.keyCode === 8) {
        // backspace/delete has been hit
        input = e.target.value.substr(0, e.target.value.length - 1);
      } else {
        input = e.target.value + String.fromCharCode(e.keyCode);
      }
    } catch (e) {}
  }

  render() {
    console.log("pro", this.props);
    let {
      first_name,
      last_name,
      user_image,
      loading_info,
      fb_notification,
      fbReviews,
      googleReviews,
      citysearchReviews,
      yelpReviews,
      view_notification_type1,
    } = this.state;

    var locations = [];
    if (this.state.AllLocations) {
      this.state.AllLocations.map((loc) => {
        if (location_id == loc.id.toString()) {
          localStorage.setItem("locationName", loc.location_name);
        }
        locations.push({ name: loc.location_name, value: loc.id.toString() });
      });
    }

    let courses = [];

    if (this.state.AllLocations) {
      this.state.AllLocations.map((data) => {
        courses = [...courses, data.location_name];
        // if (location_id == data.id.toString()) {
        //   localStorage.setItem("locationName", data.location_name);
        // }
      });
    }

    let options;
    if (this.state.search.length) {
      const searchPattern = new RegExp(
        this.state.search.map((term) => `(?=.*${term})`).join(""),
        "i"
      );
      options = courses.filter((option) => option.match(searchPattern));
    } else {
      options = courses;
    }

    let filtered_posts = [];
    if (options.length != 0) {
      options.map((data1, i) => {
        this.state.AllLocations.map((data2, j) => {
          if (data1 == data2.location_name) {
            filtered_posts = [...filtered_posts, data2];
          }
        });
      });
    }

    let loc_name = localStorage.getItem("locationName");
    // var defaultSelected=(this.state.AllLocations)?this.state.AllLocations.id:0;

    // if(this.state.changev){
    //    return <Redirect to={"dashboard#/locations/"+this.state.locationid+"/view-location"} />
    // }

    // notification

    // let total_notifications = [];

    // if (fb_notification.unseen_message_count > 0) {
    //   total_notifications = [
    //     ...total_notifications,
    //     <a
    //       href={"https://www.facebook.com/" + fb_notification.id + "/inbox"}
    //       className="dropdown-item notify-item"
    //     >
    //       <div className="notify-icon bg-success">
    //         <img src={require("../images/facebook.png")} alt="facebook" />
    //       </div>
    //       <p className="notify-details">
    //         You have {fb_notification.unseen_message_count} unread messages on
    //         your facebook page
    //       </p>
    //     </a>,
    //   ];
    // }

    // if (fb_notification.unread_notif_count > 0) {
    //   total_notifications = [
    //     ...total_notifications,
    //     <a
    //       href={"https://www.facebook.com/" + fb_notification.id}
    //       className="dropdown-item notify-item"
    //     >
    //       <div className="notify-icon bg-success">
    //         <img src={require("../images/facebook.png")} alt="facebook" />
    //       </div>
    //       <p className="notify-details">
    //         You have {fb_notification.unread_notif_count} unread notifications
    //         on your facebook page
    //       </p>
    //     </a>,
    //   ];
    // }

    // var today_date = new Date();
    // var today_time =
    //   today_date.getHours() +
    //   ":" +
    //   (today_date.getMinutes() + 1) +
    //   ":" +
    //   today_date.getSeconds();

    // for (let i = 0; i < fbReviews.length; i++) {
    //   if (fbReviews[i].created_time.slice(0, 10) == today_date) {
    //     total_notifications = [
    //       ...total_notifications,
    //       <a
    //         href={"https://www.facebook.com/" + fb_notification.id + "/reviews"}
    //         className="dropdown-item notify-item"
    //       >
    //         <div className="notify-icon bg-success">
    //           <img src={require("../images/facebook.png")} alt="facebook" />
    //         </div>
    //         <p className="notify-details">
    //           Someone give a {fbReviews[i].rating} star review
    //           <small className="text-muted">
    //             {fbReviews[i].review_text
    //               ? fbReviews[i].review_text.slice(0, 20) + "..."
    //               : ""}
    //           </small>
    //         </p>
    //       </a>,
    //     ];
    //   } else {
    //     break;
    //   }
    // }

    // let google_show_review_notification = [];

    // if (googleReviews) {
    //   for (let i = 0; i < googleReviews.length; i++) {
    //     if (googleReviews[i].createTime.slice(0, 10) == today_date) {
    //       total_notifications = [
    //         ...total_notifications,
    //         <a className="dropdown-item notify-item">
    //           <div className="notify-icon bg-success">
    //             <img src={require("../images/google.png")} alt="google" />
    //           </div>
    //           <p className="notify-details">
    //             {googleReviews[i].reviewer.displayName}
    //             leaves {googleReviews[i].starRating} star review
    //             <small className="text-muted">
    //               {googleReviews[i].comment
    //                 ? googleReviews[i].comment.slice(0, 30) + "..."
    //                 : ""}
    //             </small>
    //             <small className="text-muted">
    //               {parseInt(today_time.slice(0, 2)) -
    //                 parseInt(googleReviews[i].createTime.slice(11, 13)) ==
    //               0
    //                 ? parseInt(today_time.slice(3, 5)) -
    //                   parseInt(googleReviews[i].createTime.slice(14, 16)) +
    //                   "minutes ago"
    //                 : parseInt(today_time.slice(0, 2)) -
    //                   parseInt(googleReviews[i].createTime.slice(11, 13)) +
    //                   "hours ago"}
    //             </small>
    //           </p>
    //         </a>,
    //       ];
    //     } else {
    //       break;
    //     }
    //   }
    // }

    // let yelp_show_review_notification = [];

    // for (let i = 0; i < yelpReviews.length; i++) {
    //   if (yelpReviews[i].time_created.slice(0, 10) == today_date) {
    //     total_notifications = [
    //       ...total_notifications,
    //       <a href={yelpReviews[i].url} className="dropdown-item notify-item">
    //         <div className="notify-icon bg-success">
    //           <img src={require("../images/yelp.png")} alt="yelp" />
    //         </div>
    //         <p className="notify-details">
    //           {yelpReviews[i].user.name} give a {yelpReviews[i].rating} star
    //           review
    //           <small className="text-muted">
    //             {yelpReviews[i].text
    //               ? yelpReviews[i].text.slice(0, 20) + "..."
    //               : ""}
    //           </small>
    //           <small className="text-muted">
    //             {parseInt(today_time.slice(0, 2)) -
    //               parseInt(yelpReviews[i].time_created.slice(11, 13)) ==
    //             0
    //               ? parseInt(today_time.slice(3, 5)) -
    //                 parseInt(yelpReviews[i].time_created.slice(14, 16)) +
    //                 "minutes ago"
    //               : parseInt(today_time.slice(0, 2)) -
    //                 parseInt(yelpReviews[i].time_created.slice(11, 13)) +
    //                 "hours ago"}
    //           </small>
    //         </p>
    //       </a>,
    //     ];
    //   } else {
    //     break;
    //   }
    // }

    // let citysearch_show_review_notification = [];

    // for (let i = 0; i < citysearchReviews.length; i++) {
    //   if (citysearchReviews[i].children[6].value.slice(0, 10) == "2006-04-01") {
    //     total_notifications = [
    //       ...total_notifications,
    //       <a
    //         href={citysearchReviews[i].children[21].value}
    //         className="dropdown-item notify-item"
    //       >
    //         <div className="notify-icon bg-success">
    //           <img src={require("../images/citysearch.jpg")} alt="citysearch" />
    //         </div>
    //         <p className="notify-details">
    //           {citysearchReviews[i].children[7].value} leaves{" "}
    //           {citysearchReviews[i].children[5].value} star review
    //           <small className="text-muted">
    //             {citysearchReviews[i].children[2].value
    //               ? citysearchReviews[i].children[2].value.slice(0, 30) + "..."
    //               : ""}
    //           </small>
    //           <small className="text-muted">
    //             {parseInt(
    //               today_time.slice(0, 2) -
    //                 parseInt(
    //                   citysearchReviews[i].children[6].value.slice(11, 13)
    //                 )
    //             ) == 0
    //               ? parseInt(
    //                   today_time.slice(3, 5) -
    //                     parseInt(
    //                       citysearchReviews[i].children[6].value.slice(14, 16)
    //                     )
    //                 ) + "minutes ago"
    //               : parseInt(
    //                   today_time.slice(0, 2) -
    //                     parseInt(
    //                       citysearchReviews[i].children[6].value.slice(11, 13)
    //                     )
    //                 ) + "hours ago"}{" "}
    //           </small>
    //         </p>
    //       </a>,
    //     ];
    //   } else {
    //     break;
    //   }
    // }

    //notification

    var LocationLimit = Object.keys(this.props.membershipObj).length
      ? parseInt(this.props.membershipObj.BusinessLocationsAllowed)
      : 2;
    console.log(
      "limit check",
      LocationLimit,
      this.state.AllLocations.length < LocationLimit,
      this.state.AllLocations.length
    );
    console.log(this.props.membershipObj, "check it member");
    console.log(this.state.AllLocations, "check location");
    return (
      <div className="maindiv">
        <MDBRow className="topdash ">
          <MDBCol md="3" className="logodiv" style={{ marginTop: "5px" }}>
            <img src={require("./assets/LOGO 4.png")} alt="" />
          </MDBCol>

          <MDBCol md="5" className="searchdiv" style={{ marginTop: "12px" }}>
            <MDBRow>
              <MDBCol md="6">
                <div className="md-form vertically_center">
                  <input
                    type="text"
                    name="search"
                    // value={this.props.name}
                    placeholder={
                      this.props.name
                        ? this.props.name
                        : loc_name
                        ? loc_name
                        : "Search"
                    }
                    onChange={(e) => {
                      this.setState({ search: e.target.value.split(" ") });
                    }}
                    className="form-control searcdd "
                    // placeholder={loc_name ? loc_name : "Search"}
                    aria-label="Search"
                  />
                  {this.state.search.length == 0 ||
                  this.state.search[0] == "" ? (
                    ""
                  ) : (
                    <div className="searchtrans">
                      <div class="scrollbar">
                        <div class="overflow">
                          <ul name="language" id="language" required>
                            {filtered_posts.length != 0 ? (
                              filtered_posts.map((f, i) => (
                                <li
                                  onKeyDown={this.onKeyDown}
                                  key={`location-${i}`}
                                  onClick={this.change(
                                    f.id.toString(),
                                    f.location_name
                                  )}
                                >
                                  {f.bussiness_logo ? (
                                    <img
                                      src={
                                        "https://dashify.biz/Api/assets/upload/images/business-type-image/" +
                                        f.bussiness_logo
                                      }
                                      height={30}
                                    />
                                  ) : (
                                    <img
                                      src={require("../images/Logo2.png")}
                                      height={30}
                                    />
                                  )}
                                  {f.location_name}
                                </li>
                              ))
                            ) : (
                              <li>No Result </li>
                            )}

                            <li> total={filtered_posts.length} </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </MDBCol>
              {this.state.role !== "clientRead" &&
              this.state.role !== "clientWrite" ? (
                <MDBCol md="6" style={{ marginTop: "4px" }}>
                  {this.state.packageData === "Free" ? (
                    this.state.AllLocations.length < LocationLimit ? (
                      <MDBBtn
                        className="add_location "
                        href="/dashboard#/add-location"
                      >
                        <i className="zmdi zmdi-plus"></i> Add Location
                      </MDBBtn>
                    ) : (
                      <MDBBtn
                        className="add_location "
                        href="/dashboard#/membership-purchase-msg"
                      >
                        <i className="zmdi zmdi-plus"></i> Add Location
                      </MDBBtn>
                    )
                  ) : (
                    <MDBBtn
                      className="add_location "
                      href="/dashboard#/add-location"
                    >
                      <i className="zmdi zmdi-plus"></i> Add Location
                    </MDBBtn>
                  )}
                </MDBCol>
              ) : (
                ""
              )}
            </MDBRow>
          </MDBCol>

          <MDBCol md="4" className="rightbarmenu">
            {loading_info ? (
              <Loader
                className="loaderbox"
                type="Oval"
                color="#00BFFF"
                height={25}
                width={25}
                // timeout={3000} //3 secs
              />
            ) : (
              <MDBRow className="dddr">
                <MDBCol
                  md="7"
                  className="namedash rightdah"
                  style={{ marginTop: "8px" }}
                >
                  {first_name} {last_name}
                </MDBCol>
                <MDBCol md="3" style={{ marginTop: "8px" }}>
                  {user_image ? (
                    <img
                      src={
                        "https://dashify.biz/Api/assets/upload/images/profile-type-image/" +
                        user_image
                      }
                      alt="user"
                      className="navbar_pic"
                    />
                  ) : (
                    <AccountCircleIcon
                      fontSize="large"
                      style={{ marginTop: "9px" }}
                    />
                  )}
                </MDBCol>

                <MDBCol md="2" style={{ marginTop: "21px" }}>
                  <a className="rightdah  dropleft" data-toggle="dropdown">
                    <MoreVertIcon />
                  </a>

                  <div class="dropdown-menu drop_contant0">
                    {/* <div className='drop_contant1'>Profile Settings</div> */}
                    <div onClick={this.logout}>
                      <Link to="/" className="drop_contant1">
                        Log Out
                      </Link>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            )}
            {this.state.role !== "clientRead" &&
            this.state.role !== "clientWrite" ? (
              <MDBCol
                md="12"
                className="mobileshow"
                style={{ marginTop: "4px" }}
                cl
              >
                <MDBBtn
                  className="add_location "
                  href="/dashboard#/add-location"
                >
                  <i className="zmdi zmdi-plus"></i> Add Location
                </MDBBtn>
              </MDBCol>
            ) : (
              ""
            )}
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}
