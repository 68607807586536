import React, { Component } from "react";
import axios from "axios";

// import '.../css/Listing_management.css'

import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Footer from "./footer";
import Navbar from "./navbar";
export default class newsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsDetail: [],
      baseurl: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    console.log(this.props, "this is props");
    const data = {
      secure_pin: "digimonk",
      //   news_id: this.props.match.params.id,
      news_id: "126",
    };
    try {
      axios
        .post("https://dashify.biz/Api/admin/web_api/get_news_details", data)
        .then((response) => {
          this.setState({
            newsDetail: response.data.newsData,
            baseurl: response.data.baseurl,
          });
          console.log(response, "baseurl");
          console.log(response, "this detail news show");
          //   console.log(this.state.blogDetail[0].heading, "check it ");
        });
    } catch (e) {
      console.log(e);
    }
  }
  //   componentDidUpdate() {
  //     console.log(this.state.blogDetail, "check ehckdmdf dlfjksdfkldjfs");
  //     this.setState({
  //       blogDetail: response.data.blogDetail,
  //     });
  //   }
  render() {
    return (
      <>
        <Navbar />
        <div className="features pt-5 pb-5">
          <MDBContainer>
            {this.state.newsDetail.map((item, index) => {
              return (
                <>
                  <MDBRow key={index}>
                    <MDBCol size="7">
                      <div className="title-listing">
                        <h2 class="contact_heading">{item.heading}</h2>
                        <p>{item.description}</p>
                      </div>
                    </MDBCol>
                    <MDBCol size="5">
                      {item.image_file != "" ? (
                        <img
                          src={this.state.baseurl + item.image_file}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                    </MDBCol>
                  </MDBRow>
                </>
              );
            })}
          </MDBContainer>
        </div>

        <Footer />
      </>
    );
  }
}
