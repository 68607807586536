import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
export default class MembershipMsg extends Component {
render(){
    return(<div>You have to purchase a business package to use that feature 


        <br/>
        <Link to="/setting-main/setting-billing">Purchase Now</Link>
    </div>)
}

}