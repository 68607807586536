import React, { Component } from "react";
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Footer from "./footer";
import Navbar from "./navbar";
import check from "../assets/pricing_check.png";
import cross from "../assets/pricing_cross.png";
import { Success_Payment } from "../apis/membership";
import { secure_pin } from "../../config";
import { Link } from "react-router-dom";
import axios from "axios";
import Billing from "../billing";

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Pricing: [],
      PricingData: [],
      my: "y",
      MonthlyMembershipData: [],
      YearlyMembershipData: [],
      switch: "monthly",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      Pricing: this.props.membership,
    });

    /*----------------------Pricing All membersship API---------------*/
    var data = {
      secure_pin: "digimonk",
    };

    var config = {
      method: "post",
      url: "https://dashify.biz/Api/admin/web_api/get_all_membership",

      data: data,
    };
    axios(config)
      .then((response) => {
        console.log(response.data, "data of member ship");
        this.setState(
          {
            MonthlyMembershipData: response.data.monthlyPackage,
          },
          () =>
            console.log(
              this.state.MonthlyMembershipData,
              " monthly membershipData"
            )
        );
        this.setState(
          {
            YearlyMembershipData: response.data.yearlyPackage,
          },
          () =>
            console.log(
              this.state.YearlyMembershipData,
              " yearly membershipData"
            )
        );
      })
      .catch(function (error) {
        console.log(error, "error");
      });
  }
  componentDidUpdate() {
    if (this.state.Pricing !== this.props.membership)
      this.setState({
        Pricing: this.props.membership,
      });
  }
  onChangeTab = (m) => (e) => {
    console.log(m);
    this.setState({
      my: m,
    });
  };
  payment = (my, id) => (e) => {
    console.log("payment", my, id);
    var type = my === "y" ? "yearly" : "monthly";

    const data = {
      secure_pin: "digimonk",
      user_id: localStorage.getItem("UserId"),
      pricing_id: id,
      payment_id: "RR2342342SSFDFSDF",
      package_type: type,
    };
    console.log(secure_pin, "secure pin check");
    Success_Payment(data).then((res) => {
      console.log(res);
      alert("successfull payment done");
      window.location.href = "/dashboard";
    });
  };
  render() {
    console.log(this.state, "state");
    try {
      console.log("pricing ", this.state, this.props.pricingId);

      var Pricing = this.props.membership;
      var PricingId = this.props.pricingId;
      var { PricingData } = this.state;
      var arr = [],
        m,
        y,
        my;
      var userPric;
      if (this.props.userPric) {
        userPric = this.props.userPric;
      }

      if (Pricing) {
        Pricing.map((r) => {
          arr.push(r.data);
        });
        console.log("arr", arr);

        var NameData = Pricing.map((r) => {
          PricingData = r.data;
          PricingData.map((w) => {
            if (w.sub_heading === "Price Annually") {
              y = w.description;
            }
            if (w.sub_heading === "Price Monthly") {
              m = w.description;
            }
          });

          if (this.state.my === "y") my = y;
          else {
            my = m;
          }
          console.log(this.state.membershipData, "check data memeber");
          return (
            <MDBCol md="3">
              <div style={{ textAlign: "center" }}>
                <div className="pricing_contant1">{r.name}</div>
                <div>
                  <span className="pricing_contant2">{my}</span>
                  <span>$</span>
                </div>
                <div>
                  {this.props.flag ? (
                    <Link data-toggle="modal" data-target="#myModalSignup">
                      {" "}
                      <MDBBtn className="pricing_enable_btn">Sign Up</MDBBtn>
                    </Link>
                  ) : (
                    <>
                      {r.id === PricingId ? (
                        <MDBBtn className="pricing_enable_btn" disabled>
                          Disable
                        </MDBBtn>
                      ) : (
                        <MDBBtn
                          className="pricing_enable_btn"
                          onClick={this.payment(this.state.my, r.id)}
                        >
                          Enable
                        </MDBBtn>
                      )}
                    </>
                  )}
                </div>
              </div>
            </MDBCol>
          );
        });
      }
      if (PricingData) {
        var DataTable = PricingData.map((c, ind) => {
          var final = arr.map((e) => {
            return (
              <MDBCol md="3" className="pricing_contant5">
                {e[ind].description}{" "}
              </MDBCol>
            );
          });

          return (
            <MDBRow>
              <MDBCol md="4" className="pricing_contant4">
                {c.sub_heading}
              </MDBCol>
              <MDBCol md="8" className=" pricing_container2">
                <MDBRow>{final}</MDBRow>
              </MDBCol>
            </MDBRow>
          );
        });
      }
    } catch (e) {
      console.log(e);
    }

    var flag = this.props.flag ? this.props.flag : false;

    return (
      <div>
        {flag ? (
          <div>
            <Navbar />
            <div>
              <MDBContainer>
                <div className="contact_heading">Pricing</div>
                <div id="pricing_width1">
                  <div id="contact_contant1" className="contact_contant">
                    Companies make who sell subscription-based packages or
                    services, like SaaS companies, have to communicate their
                    value and point potential customers to the subscription plan
                    that would work best for what they need. It should be
                    apparent how these plans differ and what customers will get
                    when they sign up.
                  </div>
                </div>
              </MDBContainer>
            </div>
          </div>
        ) : (
          <div>
            Start date: {userPric.start_date}
            <br />
            End date: {userPric.end_date}
          </div>
        )}
        <div id="pricing_period">
          <MDBBtn
            onClick={this.onChangeTab("m")}
            className={`price-btn1 ${
              this.state.my === "m" ? "tab-price-btn" : ""
            }`}
          >
            Monthly
          </MDBBtn>
          <MDBBtn
            onClick={this.onChangeTab("y")}
            className={`price-btn2 ${
              this.state.my === "y" ? "tab-price-btn" : ""
            }`}
          >
            Annual
          </MDBBtn>
        </div>

        <div data-test="container" class="container container_margin">
          {this.state.my === "y" ? (
            <Billing YearlyMembershipData={this.state.YearlyMembershipData} />
          ) : (
            <Billing YearlyMembershipData={this.state.MonthlyMembershipData} />
          )}
        </div>
        {flag ? (
          <div>
            <div id="support_width2">
              <div className="contact_heading">
                Frequently asked questions about pricing{" "}
              </div>
              <div className="panel-group pricing_block" id="accordion">
                <div className="collapse_box">
                  <div
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse1"
                  >
                    <img
                      src={require("../assets/plus.png")}
                      alt=""
                      className="plus_minus"
                    />
                    <span className="support_collapse_heading">
                      Do I need to enter my credit card details to sign up?
                    </span>
                  </div>

                  <div id="collapse1" className="panel-collapse collapse ">
                    <div className="panel-body support_collapse_body">
                      Google. Google Maps. Amazon Alexa. Apple Maps. Facebook.
                      Bing. Yahoo. Yelp. It doesn’t matter which map, app, voice
                      assistant, search engine, GPS system, or social network
                      consumers use to find and engage with your business. What
                      matters is that they discover accurate, complete, and
                      compelling information at every turn.
                    </div>
                  </div>
                </div>
                <hr className="pricing_collapse_hr" />

                <div className="collapse_box">
                  <div
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse2"
                  >
                    <img
                      src={require("../assets/plus.png")}
                      alt=""
                      className="plus_minus"
                    />
                    <span className="support_collapse_heading">
                      Is there a setup free?
                    </span>
                  </div>

                  <div id="collapse2" className="panel-collapse collapse ">
                    <div className="panel-body support_collapse_body">
                      Google. Google Maps. Amazon Alexa. Apple Maps. Facebook.
                      Bing. Yahoo. Yelp. It doesn’t matter which map, app, voice
                      assistant, search engine, GPS system, or social network
                      consumers use to find and engage with your business. What
                      matters is that they discover accurate, complete, and
                      compelling information at every turn.
                    </div>
                  </div>
                </div>
                <hr className="pricing_collapse_hr" />

                <div className="collapse_box">
                  <div
                    data-toggle="collapse"
                    data-parent="#accordion"
                    href="#collapse3"
                  >
                    <img
                      src={require("../assets/plus.png")}
                      alt=""
                      className="plus_minus"
                    />
                    <span className="support_collapse_heading">
                      Is there any storage or usage limit for a content
                      repository?
                    </span>
                  </div>

                  <div id="collapse3" className="panel-collapse collapse ">
                    <div className="panel-body support_collapse_body">
                      Google. Google Maps. Amazon Alexa. Apple Maps. Facebook.
                      Bing. Yahoo. Yelp. It doesn’t matter which map, app, voice
                      assistant, search engine, GPS system, or social network
                      consumers use to find and engage with your business. What
                      matters is that they discover accurate, complete, and
                      compelling information at every turn.
                    </div>
                  </div>
                </div>
                <hr className="pricing_collapse_hr" />
              </div>
            </div>
            <div id="asknow">
              <span id="support_contant3">Any more questions?</span>
              <span id="support_contant4" className="support_contant">
                Ask it now!{" "}
              </span>
              {/* <hr id='line'/> */}
            </div>
            {/* </MDBContainer>
        </div> */}
            <Footer />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
