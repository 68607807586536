import React, { Component } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  HashRouter,
  NavLink,
  Redirect,
} from "react-router-dom";
import CampaignPart2 from "./campaignpart2";
import ReviewGenerationCampaign from "./review-generation-campaign";

import ReviewTracking from "./review-tracking";
import ViewAListing from "./view-listing";
import VoiceListing from "./voice_listing/voice-listing";

import ReviewAnalytics from "./review-analytics";
import RankingAnalytics from "./ranking-analytics";
import Topbarmenu from "./topbarmenu";

import User_profile from "./user_profile";
import Activity from "./Activity";
import SettingMain from "./setting-main";
import SettingAccounts from "./setting-accounts";
import SettingPeople from "./setting-people";
import SettingBilling from "./setting-billing";
import SettingEmail from "./setting-email";
import SettingUpdateCard from "./setting-updateCard";
import SideBar from "./sidebar";
import PromotionalPost from "./promotional-post";
import PromotionalPostSorry from "./promotional-post-sorry";
import ViewLocations from "./view-locations";
import LocationManager from "./location-manager";
import ProfileAnalytics from "./profile-analytics";
import ViewListing from "./view-listing";
import Overview from "./overview";
import ReviewGenerationStats from "./review-generation-stats";
import AddLocation from "./add-location";
import ConnectedAccounts from "./connectedaccounts";
import GoogleConnectedAccounts from "./google-connectedaccounts";
import LinkedinConnectedAccounts from "./linkedin-connectedaccounts";
import HereRelatedLocation from "./here-related-location";
import TomtomRelatedLocation from "./tomtom-related-location";
import PageNotFound from "./page-not-found";
import CommonLogin from "./CommonLogin";
import InviteNewUser from "./InviteNewUser";
import BulkAddUser from "./BulkAddUser";
import { get_login_user_info } from "./apis/user";
import { secure_pin } from "../config";
import SettingAgency from "./setting-agency";
import Notification_setting from "./Notification_setting";
import MembershipMsg from "./membership_msg";

export default class MainApp extends Component {
  constructor() {
    super();
    this.state = {
      role: "",
      name: "",
      showmenu: !window.matchMedia("(max-width: 768px)").matches,
      membership: [],
      pricingId: "1",
      home: false,
      userPric: [],
      // smallSize: window.matchMedia("(max-width: 768px)").matches,
    };
    this.handleLocationName = this.handleLocationName.bind(this);
  }

  Hidemenu = () => {
    this.setState({
      showmenu: !this.state.showmenu,
    });
  };

  componentDidMount() {
    if (!localStorage.getItem("UserId")) {
      this.setState({ home: true });
    }
    let data = { user_id: localStorage.getItem("UserId"), secure_pin };

    this.setState({
      membership: this.props.membership,
    });
    get_login_user_info(data)
      .then((res) => {
        console.log("user info0", res.data);
        console.log("user info img", res.data.users_login[0].profile_image);
        if (res.data && res.data.users_login) {
          this.setState({
            users_login: res.data.users_login,
            role: res.data.users_login[0].role,
            pricingId: res.data.memberData[0].pricing_id,
            userPric: res.data.memberData[0],
          });
          console.log("statr value", this.state);
        } else {
          this.setState({ loading_info: false, loading_image: false });
        }
      })
      .catch((err) => {
        console.log("user info err", err);
        this.setState({ loading_info: false, loading_image: false });
      });
  }

  componentDidUpdate() {
    if (!localStorage.getItem("UserId")) {
      this.setState({ home: true });
    }
  }

  handleLocationName(name) {
    this.setState({ name });
  }
  render() {
    console.log("membership main", this.props.membership, this.state.pricingId);

    if (this.state.home) {
      return <Redirect to="/" />;
    }
    if (this.props.membership) {
      var filterRoutes = this.props.membership.filter(
        (r) => r.id === this.state.pricingId
        // console.log(r)
      );
    }
    var finalObj = {};
    if (filterRoutes) {
      filterRoutes = filterRoutes.map((data) => {
        data.data.map((r) => {
          console.log(r);
          finalObj[r.sub_heading.replaceAll(" ", "")] = r.description;
        });
      });
      console.log("obj", finalObj);
    }
    return (
      <div>
        <Topbarmenu
          role={this.state.role}
          name={this.state.name}
          membershipObj={finalObj}
        />
        {this.state.showmenu ? (
          <>
            <SideBar />
          </>
        ) : null}

        <HashRouter>
          <div
            className="content-page"
            style={{ marginLeft: this.state.showmenu ? 240 : 0 }}
          >
            <span className="menubar" onClick={this.Hidemenu}>
              <i class="fas fa-bars"></i>
            </span>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="breadcrumb-menu">
                    <ul>
                      <li>
                        <NavLink
                          to={
                            "/locations/" +
                            localStorage.getItem("locationId") +
                            "/overview"
                          }
                          className="underline-from-left"
                        >
                          Overview
                        </NavLink>
                      </li>

                      <li className="dropdown">
                        <NavLink
                          to={
                            "/locations/" +
                            localStorage.getItem("locationId") +
                            "/view-listing"
                          }
                          className="underline-from-left dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Listing
                        </NavLink>
                        <ul className="dropdown-menu">
                          <li>
                            <NavLink
                              to={
                                "/locations/" +
                                localStorage.getItem("locationId") +
                                "/view-listing"
                              }
                            >
                              View Listing
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={
                                "/locations/" +
                                localStorage.getItem("locationId") +
                                "/view-location"
                              }
                            >
                              View Location
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to={
                                "/locations/" +
                                localStorage.getItem("locationId") +
                                "/voice-listing"
                              }
                            >
                              Voice Listing
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li className="dropdown">
                        <NavLink
                          to={
                            "/locations/" +
                            localStorage.getItem("locationId") +
                            "/profile-analytics"
                          }
                          className="underline-from-left dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Analytics
                        </NavLink>

                        <ul className="dropdown-menu">
                          <li>
                            <NavLink
                              to={
                                "/locations/" +
                                localStorage.getItem("locationId") +
                                "/profile-analytics"
                              }
                            >
                              Profile Analytics
                            </NavLink>
                          </li>
                          <li>
                            {finalObj.ReviewAnalytics === "Yes" ? (
                              <NavLink
                                to={
                                  "/locations/" +
                                  localStorage.getItem("locationId") +
                                  "/review-analytics"
                                }
                              >
                                Review Analytics
                              </NavLink>
                            ) : (
                              <NavLink to="/membership-purchase-msg">
                                Review Analytics
                              </NavLink>
                            )}
                          </li>
                          <li>
                            {finalObj.KeywordResearchtool === "Yes" ? (
                              <NavLink
                                to={
                                  "/locations/" +
                                  localStorage.getItem("locationId") +
                                  "/ranking-analytics"
                                }
                              >
                                Ranking Analytics
                              </NavLink>
                            ) : (
                              <NavLink to="/membership-purchase-msg">
                                Ranking Analytics
                              </NavLink>
                            )}
                          </li>
                        </ul>
                      </li>

                      <li>
                        <NavLink
                          to="/location-manager"
                          className="underline-from-left"
                        >
                          Location Manager
                        </NavLink>
                      </li>
                      <li className="dropdown">
                        <NavLink
                          to={
                            "/locations/" +
                            localStorage.getItem("locationId") +
                            "/review-tracking"
                          }
                          className="underline-from-left dropdown-toggle"
                          data-toggle="dropdown"
                        >
                          Review Generations
                        </NavLink>
                        <ul className="dropdown-menu">
                          {/* <li>
                            <NavLink
                              to={
                                "/locations/" +
                                localStorage.getItem("locationId") +
                                "/review-generation-campaign"
                              }
                            >
                              Generation
                            </NavLink>
                          </li> */}
                          <li>
                            <NavLink
                              to={
                                "/locations/" +
                                localStorage.getItem("locationId") +
                                "/review-tracking"
                              }
                            >
                              Tracking
                            </NavLink>
                          </li>
                          {/* <li>
                            <NavLink to="/b">Wedgets</NavLink>
                          </li> */}
                          <li>
                            {finalObj.ReviewsbySMS === "No" ||
                            finalObj.Reviewsbyemail === "No" ? (
                              <NavLink to="/membership-purchase-msg">
                                Review Generation Stats
                              </NavLink>
                            ) : (
                              <NavLink
                                to={
                                  "/locations/" +
                                  localStorage.getItem("locationId") +
                                  "/review-generation-stats"
                                }
                              >
                                Review Generation Stats
                              </NavLink>
                            )}
                          </li>
                        </ul>
                      </li>

                      <li className="dropdown">
                        {finalObj.PromotionalPost === "Yes" ? (
                          <NavLink
                            to={
                              "/locations/" +
                              localStorage.getItem("locationId") +
                              "/promotional"
                            }
                            className="underline-from-left"
                          >
                            Promotional Posts
                          </NavLink>
                        ) : (
                          <NavLink
                            to="/membership-purchase-msg"
                            className="underline-from-left"
                          >
                            Promotional Posts
                          </NavLink>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="content">
              <Switch>
                <Route
                  exact
                  path="/locations/:locationId/overview"
                  render={(props) => <Overview {...props} />}
                />

                <Route
                  exact
                  path="/setting-main/setting-people/invite-new-user"
                  render={(props) => <InviteNewUser {...props} />}
                />

                <Route
                  exact
                  path="/setting-main/setting-people/bulk-user"
                  render={(props) => <BulkAddUser {...props} />}
                />

                <Route
                  exact
                  path="/setting-main/setting-people/user/edit/:id"
                  render={(props) => <InviteNewUser {...props} />}
                />
                <Route
                  exact
                  path="/locations/:locationId/campaignpart2/:campaign_id"
                  render={(props) => <CampaignPart2 {...props} />}
                />
                <Route
                  exact
                  path="/locations/:locationId/review-generation-campaign"
                  render={(props) => <ReviewGenerationCampaign {...props} />}
                />
                <Route
                  exact
                  path="/common-login/:name"
                  component={CommonLogin}
                />
                <Route
                  path="/membership-purchase-msg"
                  component={MembershipMsg}
                />
                <Route
                  exact
                  path="/locations/:locationId/review-tracking"
                  render={(props) => <ReviewTracking {...props} />}
                />
                <Route exact path="/" component={Overview} />
                <Route
                  exact
                  path="/locations/:locationId/view-listing"
                  render={(props) => (
                    <ViewListing {...props} membershipObj={finalObj} />
                  )}
                />
                <Route
                  exact
                  path="/locations/:locationId/voice-listing"
                  render={(props) => <VoiceListing {...props} />}
                />
                <Route
                  exact
                  path="/locations/:locationId/view-location"
                  render={(props) => (
                    <ViewLocations role={this.state.role} {...props} />
                  )}
                />

                <Route
                  exact
                  path="/location-manager"
                  // component={LocationManager}
                  render={(props) => (
                    <LocationManager
                      role={this.state.role}
                      handleLocationName={this.handleLocationName}
                      {...props}
                    />
                  )}
                />

                <Route
                  exact
                  path="/locations/:locationId/review-analytics"
                  render={(props) => <ReviewAnalytics {...props} />}
                />
                <Route
                  exact
                  path="/locations/:locationId/ranking-analytics"
                  render={(props) => <RankingAnalytics {...props} />}
                />
                <Route
                  exact
                  path="/locations/:locationId/profile-analytics"
                  render={(props) => <ProfileAnalytics {...props} />}
                />

                <Route
                  exact
                  path="/locations/:locationId/promotional"
                  render={(props) => <PromotionalPost {...props} />}
                />
                <Route
                  exact
                  path="/promotional-sorry"
                  component={PromotionalPostSorry}
                />
                <Route
                  exact
                  path="/locations/:locationId/review-generation-stats"
                  render={(props) => <ReviewGenerationStats {...props} />}
                />
                <Route exact path="/add-location" component={AddLocation} />

                <Route exact path="/user-profile" component={User_profile} />
                <Route exact path="/activity" component={Activity} />
                <Route
                  exact
                  path="/setting-main"
                  render={(props) => (
                    <SettingMain role={this.state.role} {...props} />
                  )}
                />
                <Route
                  path="/setting-main/setting-accounts"
                  render={(props) => (
                    <SettingAccounts role={this.state.role} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/setting-main/setting-people"
                  render={(props) => (
                    <SettingPeople role={this.state.role} {...props} />
                  )}
                />
                <Route
                  exact
                  path="/setting-main/setting-agency"
                  render={(props) => (
                    <SettingAgency role={this.state.role} {...props} />
                  )}
                />

                <Route
                  exact
                  path="/setting-main/setting-notification"
                  render={(props) => (
                    <Notification_setting role={this.state.role} {...props} />
                  )}
                />

                <Route
                  exact
                  path="/setting-main/setting-billing"
                  render={(props) => (
                    <SettingBilling
                      role={this.state.role}
                      membership={this.props.membership}
                      pricingId={this.state.pricingId}
                      userPric={this.state.userPric}
                    />
                  )}
                />
                {/* <Route
                  exact
                  path="/setting-main/setting-email"
                  component={SettingEmail}
                /> */}

                <Route
                  exact
                  path="/setting-main/setting-updateCard"
                  render={(props) => (
                    <SettingUpdateCard role={this.state.role} {...props} />
                  )}
                />

                <Route
                  exact
                  path="/connectedaccounts/:redirect_to"
                  render={(props) => <ConnectedAccounts {...props} />}
                />
                <Route
                  exact
                  path="/google-connectedaccounts/:state"
                  render={(props) => <GoogleConnectedAccounts {...props} />}
                />
                <Route
                  exact
                  path="/linkedin-connectedaccounts/:state/:redirect_to/:locationId"
                  render={(props) => <LinkedinConnectedAccounts {...props} />}
                />
                <Route
                  exact
                  path="/here-related-location"
                  component={HereRelatedLocation}
                />
                <Route
                  exact
                  path="/tomtom-related-location"
                  component={TomtomRelatedLocation}
                />
                <Route path="/" component={PageNotFound} />
              </Switch>
            </div>
          </div>
        </HashRouter>
      </div>
    );
  }
}
