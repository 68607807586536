import React, { Component } from "react";
import { MDBCol, MDBRow, MDBDataTableV5, MDBContainer, MDBBtn } from "mdbreact";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Checkbox } from "@material-ui/core";
import google from "../assets/google.png";
import bing from "../assets/bing.png";
import yelp from "../assets/yelp.png";
import scanner_img1 from "../assets/scanner_img1.png";
import scanner_img2 from "../assets/scanner_img2.png";
import scanner_img3 from "../assets/scanner_img3.png";
import scanner_img4 from "../assets/scanner_img4.png";
import scanner_img5 from "../assets/scanner_img5.png";
import scanner_img6 from "../assets/yelp.png";
import check_img from "../assets/check_img.png";
import cross_img from "../assets/cross_img.png";
import Footer from "./footer";
import Navbar from "./navbar";
import Radial_chart from "../utils/Radial_chart";
import { ScanBusiness } from "../apis/scanTool";
import { secure_pin } from "../../config";
import Loader from "react-loader-spinner";
import Signup from "./signup";
import { Link } from "react-router-dom";
export default class Scanner extends Component {
  state = {
    AllList: [],
    Break2: {},
    Break1: {},
    voice: [{}],
    loading: true,
  };
  componentDidMount() {
    window.scrollTo(0, 0);

    var id = this.props.match.params.id;

    const data = {
      secure_pin,
      search_id: id,
    };

    ScanBusiness(data)
      .then((res) => {
        console.log("res", res);
        this.setState({
          AllList: res.data.search_data,
          dat: true,
          basic: res.data.scantool_data,
          voice: res.data.voice_data,
          Break1: res.data.Score_Breakdown1[0],
          Break2: res.data.Score_Breakdown2[0],
          loading: false,
        });
        console.log(res.data.search_data, "all list of data new");
      })
      .catch((res) => {
        console.log("not active");
        this.setState({ loading: false });
      });
  }

  render() {
    let basic = this.state.basic;
    var name,
      cate,
      country,
      phone,
      zipcode = "";

    if (basic) {
      var temp = basic[1];
      basic = basic[0];

      name = basic.business_name;
      cate = temp.categoryName;
      country = temp.countryName;
      phone = basic.mobile_no;
      zipcode = basic.zipcode;
      console.log("che", temp);
    }

    let { Break1, Break2 } = this.state;

    console.log(this.state);
    console.log(
      this.state.AllList,
      "chck chdek xc sjfkfnsdfj fdljsjfk fsdkhfsdj"
    );
    let { AllList, voice } = this.state;
    let voiced;
    if (voice) voiced = this.state.voice[0];

    let BigDiv, AllListingDiv;

    if (AllList) {
      BigDiv = AllList.map((r) => {
        return (
          <>
            {" "}
            {r.status !== "0" ? (
              <MDBCol md="4">
                <div className="scanner_box">
                  <MDBRow>
                    <MDBCol md="8">
                      <img src={r.icon} className="scanner_google_img" />
                    </MDBCol>

                    <MDBCol md="4">
                      <CircularProgressbarWithChildren
                        value={r.Score}
                        styles={buildStyles({
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          pathColor: `#8264C6 `,
                          trailColor: "#ffffff",
                        })}
                      >
                        <div className="scanner_contant6">{r.Score}%</div>
                        <div className="scanner_contant7">Score</div>
                      </CircularProgressbarWithChildren>
                    </MDBCol>
                  </MDBRow>

                  {/* <MDBRow>
                <MDBCol md='3'>
                <div className='scanner_contant1'>Link</div>
                </MDBCol>
                <MDBCol md='9'>
                <div><Checkbox/></div>
                </MDBCol>
              </MDBRow> */}

                  <MDBRow>
                    <MDBCol md="3">
                      <div className="scanner_contant1">Name:</div>
                    </MDBCol>
                    <MDBCol md="9">
                      <div className="scanner_contant2">
                        {" "}
                        {r.business_name}{" "}
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="3">
                      <div className="scanner_contant1">Address:</div>
                    </MDBCol>
                    <MDBCol md="9">
                      <div className="scanner_contant2"> {r.address} </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="3">
                      <div className="scanner_contant1">Phone:</div>
                    </MDBCol>
                    <MDBCol md="9">
                      <div className="scanner_contant2">{r.display_phone}</div>
                    </MDBCol>
                  </MDBRow>
                  {r.Type === "City Grid Media" ? (
                    <MDBRow>
                      <MDBCol md="3">
                        <div className="scanner_contant1">Website:</div>
                      </MDBCol>
                      <MDBCol md="9">
                        <div className="scanner_contant2">
                          {r.websiteurlNew}
                        </div>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    ""
                  )}
                  <div className="scanner_contant3">Detailed breakdown</div>

                  <MDBRow>
                    <MDBCol md="9">
                      <div className="scanner_contant2">Categories</div>
                    </MDBCol>

                    <MDBCol md="3">
                      <MDBBtn
                        className={
                          r.categories === "Yes"
                            ? "scanner_yes_btn"
                            : "scanner_no_btn"
                        }
                      >
                        {r.categories}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="9">
                      <div className="scanner_contant2">
                        Website URL Present
                      </div>
                    </MDBCol>

                    <MDBCol md="3">
                      <MDBBtn
                        className={
                          r.websiteurl === "Yes"
                            ? "scanner_yes_btn"
                            : "scanner_no_btn"
                        }
                      >
                        {r.websiteurl}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="9">
                      <div className="scanner_contant2">Hours of operation</div>
                    </MDBCol>

                    <MDBCol md="3">
                      <MDBBtn
                        className={
                          r.hourslisting === "Yes"
                            ? "scanner_yes_btn"
                            : "scanner_no_btn"
                        }
                      >
                        {r.hourslisting}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="9">
                      <div className="scanner_contant2">Photos present</div>
                    </MDBCol>

                    <MDBCol md="3">
                      <MDBBtn
                        className={
                          r.photoes === "Yes"
                            ? "scanner_yes_btn"
                            : "scanner_no_btn"
                        }
                      >
                        {r.photoes}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="9">
                      <div className="scanner_contant2">Reviews</div>
                    </MDBCol>

                    <MDBCol md="3">
                      <MDBBtn
                        className={
                          r.reviews === "Yes"
                            ? "scanner_yes_btn"
                            : "scanner_no_btn"
                        }
                      >
                        {r.reviews}
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            ) : (
              ""
            )}
          </>
        );
      });

      AllListingDiv = AllList.map((r) => {
        return (
          <MDBRow className="scanner_hr">
            <MDBCol md="1">
              <img src={r.icon} alt="" className="scanner_table_img" />
            </MDBCol>
            <MDBCol md="3" className="scanner_margin_table2">
              <div className="scanner_table_contant1">{r.Type}</div>
            </MDBCol>
            <MDBCol md="2" className="scanner_margin_table">
              <div className="scanner_table_contant3">
                {" "}
                {r.business_name ? r.business_name : "-"}{" "}
              </div>
            </MDBCol>

            <MDBCol md="2" className="scanner_margin_table">
              <div className="scanner_table_contant3">
                {" "}
                {r.address ? r.address : "-"}{" "}
              </div>
            </MDBCol>

            <MDBCol md="2" className="scanner_margin_table">
              <div className="scanner_table_contant3">
                {r.display_phone ? r.display_phone : "-"}
              </div>
            </MDBCol>

            <MDBCol md="2" className="scanner_nopadding scanner_margin_table">
              {r.status === "0" ? (
                <div>
                  <MDBBtn className="scanner_table_cross_btn">
                    <img src={cross_img} className="check_img" />
                  </MDBBtn>
                  <span className="scanner_table_contant3">
                    Incorrect information
                  </span>
                </div>
              ) : (
                <MDBCol
                  md="2"
                  className="scanner_nopadding scanner_margin_table"
                >
                  <div>
                    <MDBBtn className="scanner_table_check_btn">
                      <img src={check_img} className="check_img" />
                    </MDBBtn>
                    <span className="scanner_table_contant3">
                      Listing Found
                    </span>
                  </div>
                </MDBCol>
              )}
            </MDBCol>
          </MDBRow>
        );
      });
    }

    console.log(this.state);

    return (
      <div>
        <Navbar />
        {this.state.loading ? (
          <Loader
            type="Oval"
            color="#00BFFF"
            height={250}
            width={250}

            // timeout={3000} //3 secs
          />
        ) : (
          <MDBContainer>
            <div className="scanner_heading">Dashify scan results</div>
            <MDBRow>
              <MDBCol md="2" className="scanner_radial_chart">
                {/* <Radial_chart/> */}

                <img
                  src={scanner_img1}
                  alt="scanner_img1"
                  id="scanner_img1"
                  style={{ margin: "0px" }}
                />
              </MDBCol>
              <MDBCol md="4">
                <div className="scanner_contant1"> {name}</div>
                <div className="scanner_contant2"> {cate} </div>
                <div className="scanner_contant2">
                  {" "}
                  {country}, {zipcode}{" "}
                </div>
                <div className="scanner_contant2"> {phone} </div>
                {/* <div>
                            <span className='scanner_contant2'>This isn’t my business information</span>
                            <span><Checkbox/></span>
                        </div> */}
                <div>
                  <Link data-toggle="modal" data-target="#myModalSignup">
                    <MDBBtn id="scanner_fix_btn">Fix all errors</MDBBtn>
                  </Link>
                </div>
              </MDBCol>
              <MDBCol md="6">
                <img src={scanner_img1} alt="" id="scanner_img1" />
              </MDBCol>
            </MDBRow>
            <div className="scanner_heading">Directories</div>
            <MDBRow>
              {BigDiv}
              {/* 

                  <MDBCol md='4'>
                    <div className='scanner_box'>
                    <MDBRow>
                        <MDBCol md='8' >
                          <img src={google}  className='scanner_google_img'  />
                        </MDBCol>
                        <MDBCol md='4' >
                        <CircularProgressbarWithChildren value={50}  
                        styles={buildStyles({
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          pathColor: `#8264C6 `,
                          trailColor: '#ffffff',
                        })}>
                          <div className='scanner_contant6'>20%</div>
                          <div className='scanner_contant7'>Score</div>
                          </CircularProgressbarWithChildren>;
                        </MDBCol>
                      </MDBRow>
                    
                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Link</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div><Checkbox/></div>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Name:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>Kandl Water Condition Inc.</div>
                        </MDBCol>
                      </MDBRow>

                       <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Adress:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>264 Manitoba St. Spicar, 56288, USA</div>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Phone:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>(320)706-50-20</div>
                        </MDBCol>
                      </MDBRow>
                     
                  <div className='scanner_contant3'>Detailed breakdown</div>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Categories</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Website URL Present</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Hours of operation</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Photos present</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Reviews</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                 </div>
                  </MDBCol>

                  <MDBCol md='4'>
                    <div className='scanner_box'>
                    <MDBRow>
                        <MDBCol md='8' >
                          <img src={bing}  className='scanner_bing_img'  />
                        </MDBCol>
                        <MDBCol md='4' >
                        <CircularProgressbarWithChildren value={98}  
                        styles={buildStyles({
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          pathColor: `#8264C6 `,
                          trailColor: '#ffffff',
                        })}>
                          <div className='scanner_contant6'>98%</div>
                          <div className='scanner_contant7'>Score</div>
                          </CircularProgressbarWithChildren>;
                        </MDBCol>
                      </MDBRow>
                    
                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Link</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div><Checkbox/></div>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Name:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>Kandl Water Condition Inc.</div>
                        </MDBCol>
                      </MDBRow>

                       <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Adress:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>264 Manitoba St. Spicar, 56288, USA</div>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Phone:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>(320)706-50-20</div>
                        </MDBCol>
                      </MDBRow>
                     
                  <div className='scanner_contant3'>Detailed breakdown</div>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Categories</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Website URL Present</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Hours of operation</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Photos present</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Reviews</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                 </div>
                  </MDBCol>

                  <MDBCol md='4'>
                    <div className='scanner_box'>
                    <MDBRow>
                        <MDBCol md='8' >
                          <img src={yelp}  className='scanner_yelp_img'  />
                        </MDBCol>
                        <MDBCol md='4' >
                        <CircularProgressbarWithChildren value={80}  
                        styles={buildStyles({
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: 'butt',
                          pathColor: `#8264C6 `,
                          trailColor: '#ffffff',
                        })}>
                          <div className='scanner_contant6'>80%</div>
                          <div className='scanner_contant7'>Score</div>
                          </CircularProgressbarWithChildren>;
                        </MDBCol>
                      </MDBRow>
                    
                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Link</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div><Checkbox/></div>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Name:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>Kandl Water Condition Inc.</div>
                        </MDBCol>
                      </MDBRow>

                       <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Adress:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>264 Manitoba St. Spicar, 56288, USA</div>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow>
                        <MDBCol md='3'>
                        <div className='scanner_contant1'>Phone:</div>
                        </MDBCol>
                        <MDBCol md='9'>
                        <div className='scanner_contant2'>(320)706-50-20</div>
                        </MDBCol>
                      </MDBRow>
                     
                  <div className='scanner_contant3'>Detailed breakdown</div>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Categories</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Website URL Present</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Hours of operation</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Photos present</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_yes_btn'>Yes</MDBBtn>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md='9'>
                    <div className='scanner_contant2'>Reviews</div>
                    </MDBCol>
                    
                    <MDBCol md='3'>
                      <MDBBtn className='scanner_no_btn'>No</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                 </div>
                  </MDBCol>
                */}
            </MDBRow>

            {/* <div className="blog-pagination">
              <ul>
                <li>
                  <img src={require("../assets/arrow-left.png")} />
                </li>
                <li>
                  <span>1</span>
                </li>
                <li>
                  <span>2</span>
                </li>
                <li>
                  <span>3</span>
                </li>
                <li>
                  <img src={require("../assets/arrow-right.png")} />
                </li>
              </ul>
            </div> */}

            <MDBRow className="scanner_box scanner_margin1">
              <MDBCol md="2" className="scanner_margin3">
                <div className="scanner_contant5">Score Breakdown</div>
              </MDBCol>
              <MDBCol md="2" className="scanner_box2">
                <MDBRow>
                  <MDBCol md="8" className="scanner_nopadding">
                    <div className="scanner_contant4">Listings present</div>
                  </MDBCol>
                  <MDBCol md="4" className="scanner_nopadding">
                    <MDBBtn className="scanner_btn">
                      {" "}
                      {Break1.Listings_present}{" "}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>

              <MDBCol md="2" className="scanner_box2">
                <MDBRow>
                  <MDBCol md="8" className="scanner_nopadding">
                    <div className="scanner_contant4">Listings checked</div>
                  </MDBCol>
                  <MDBCol md="4" className="scanner_nopadding">
                    <MDBBtn className="scanner_btn">
                      {Break1.Listings_checked}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="2" className="scanner_box2">
                <MDBRow>
                  <MDBCol md="8" className="scanner_nopadding">
                    <div className="scanner_contant4"> Presence score</div>
                  </MDBCol>
                  <MDBCol md="4" className="scanner_nopadding">
                    <MDBBtn className="scanner_btn">
                      {Break1.Presence_score}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="2" className="scanner_margin4">
                <CircularProgressbarWithChildren
                  value={Break1.NAP_Score}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    pathColor: `#8264C6 `,
                    trailColor: "#ffffff",
                    width: "115px",
                    height: "115px",
                  })}
                >
                  <div className="scanner_contant6">{Break1.NAP_Score}%</div>
                  <div className="scanner_contant7">
                    <div>NAP</div>
                    <div>Score</div>
                  </div>
                </CircularProgressbarWithChildren>
              </MDBCol>
            </MDBRow>

            {/* <div  >
            <MDBDataTableV5 hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={datatable} />
                </div> */}
            <div>
              <MDBRow className="scanner_hr" id="scanner_hr1">
                <MDBCol md="2" className="scanner_table_heading offset-md-4">
                  <div className="scanner_table_heading_contant">
                    Bussiness Name
                  </div>
                </MDBCol>
                <MDBCol md="2" className="scanner_table_heading2 ">
                  <div className="scanner_table_heading_contant">Address</div>
                </MDBCol>
                <MDBCol md="2" className="scanner_table_heading2 ">
                  <div className="scanner_table_heading_contant">Phone</div>
                </MDBCol>
                <MDBCol md="2" className="scanner_table_heading2 ">
                  <div className="scanner_table_heading_contant">Status</div>
                </MDBCol>
              </MDBRow>

              {AllListingDiv}

              {/* <MDBRow className='scanner_hr'>
                  <MDBCol md='1'>
                  <img src={require("../assets/home_icon1.png")} alt="" className='scanner_table_img'/>
                  </MDBCol>
                  <MDBCol md='3' className='scanner_margin_table2'>
                    <div className='scanner_table_contant1'>Google</div>
                    <div className='scanner_table_contant2'>this is check for scanner content</div>
                  </MDBCol>
                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>this is check for scanner content</div>
                  </MDBCol>

                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>this is check for scanner content</div>
                  </MDBCol>

                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>(320)647-53-52</div>
                  </MDBCol>

                   <MDBCol md='2' className='scanner_nopadding scanner_margin_table'>
                    <div>
                    <MDBBtn className='scanner_table_exclam_btn'>!</MDBBtn>
                      <span className='scanner_table_contant3'>Incorrect information</span>
                    </div>
                  </MDBCol>
                </MDBRow>
                

                <MDBRow className='scanner_hr'>
                  <MDBCol md='1'>
                  <img src={require("../assets/home_icon2.png")} alt="" className='scanner_table_img'/>
                  </MDBCol>
                  <MDBCol md='3' className='scanner_margin_table2'>
                    <div className='scanner_table_contant1'>BING</div>
                    <div className='scanner_table_contant2'>this is check for scanner content</div>
                  </MDBCol>
                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>this is check for scanner content</div>
                  </MDBCol>

                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>#123 2nd flore, State this is check for scanner content</div>
                  </MDBCol>

                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>(320)647-53-52</div>
                  </MDBCol>

                  <MDBCol md='2'  className='scanner_nopadding scanner_margin_table'>
                    <div>
                     <MDBBtn className='scanner_table_cross_btn'><img src={cross_img} className='check_img'/></MDBBtn>
                      <span className='scanner_table_contant3'>No information</span>
                    </div>
                  </MDBCol>
                </MDBRow>
                

                <MDBRow className='scanner_hr'>
                  <MDBCol md='1'>
                  <img src={require("../assets/home_icon3.png")} alt="" className='scanner_table_img'/>
                  </MDBCol>
                  <MDBCol md='3' className='scanner_margin_table2'>
                    <div className='scanner_table_contant1'>YELP</div>
                    <div className='scanner_table_contant2'>this is check for scanner content</div>
                  </MDBCol>
                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>this is check for scanner content</div>
                  </MDBCol>

                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>this is check for scanner content</div>
                  </MDBCol>

                  <MDBCol md='2' className='scanner_margin_table'>
                    <div className='scanner_table_contant3'>(320)647-53-52</div>
                  </MDBCol>

                   <MDBCol md='2' className='scanner_nopadding scanner_margin_table'>
                    <div>
                    <MDBBtn className='scanner_table_check_btn'><img src={check_img} className='check_img'/></MDBBtn>
                      <span className='scanner_table_contant3'>No information</span>
                    </div>
                  </MDBCol>
                </MDBRow>
                 */}
            </div>
            <div className="scanner_heading">Voice search readiness</div>

            <MDBRow className="scanner_box ">
              <MDBCol md="2" className="scanner_margin3">
                <div className="scanner_contant5">Score Breakdown</div>
              </MDBCol>
              <MDBCol md="2" className="scanner_box2">
                <MDBRow>
                  <MDBCol md="8" className="scanner_nopadding">
                    <div className="scanner_contant4">Listings present</div>
                  </MDBCol>
                  <MDBCol md="4" className="scanner_nopadding">
                    <MDBBtn className="scanner_btn">
                      {Break2.Listings_present}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>

              <MDBCol md="2" className="scanner_box2">
                <MDBRow>
                  <MDBCol md="8" className="scanner_nopadding">
                    <div className="scanner_contant4">Listings checked</div>
                  </MDBCol>
                  <MDBCol md="4" className="scanner_nopadding">
                    <MDBBtn className="scanner_btn">
                      {Break2.Listings_checked}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="2" className="scanner_box2">
                <MDBRow>
                  <MDBCol md="8" className="scanner_nopadding">
                    <div className="scanner_contant4"> Presence score</div>
                  </MDBCol>
                  <MDBCol md="4" className="scanner_nopadding">
                    <MDBBtn className="scanner_btn">
                      {Break2.Presence_score}
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="2" className="scanner_margin4">
                <CircularProgressbarWithChildren
                  value={Break2.NAP_Score}
                  styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "butt",
                    pathColor: `#8264C6 `,
                    trailColor: "#ffffff",
                  })}
                >
                  <div className="scanner_contant6">{Break2.NAP_Score}%</div>
                  <div className="scanner_contant7">
                    <div>Voice Search</div>
                    <div>readiness</div>
                  </div>
                </CircularProgressbarWithChildren>
                ;
              </MDBCol>
            </MDBRow>
            <div className="scanner_margin1">
              <MDBRow className="scanner_margin5">
                <MDBCol md="6" className="scanner_margin2">
                  <MDBRow>
                    <MDBCol md="8">
                      <img src={scanner_img2} />
                    </MDBCol>
                    <MDBCol md="4">
                      {voiced.SamsungBixby ? (
                        <MDBBtn className="scanner_check_btn">
                          <img src={check_img} className="check_img" />
                        </MDBBtn>
                      ) : (
                        <MDBBtn className="scanner_cross_btn">
                          <img src={cross_img} className="check_img" />
                        </MDBBtn>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

                <MDBCol md="6" className="scanner_margin2">
                  <MDBRow>
                    <MDBCol md="8">
                      <img src={scanner_img3} />
                    </MDBCol>
                    <MDBCol md="4">
                      {voiced.Assistant ? (
                        <MDBBtn className="scanner_check_btn">
                          <img src={check_img} className="check_img" />
                        </MDBBtn>
                      ) : (
                        <MDBBtn className="scanner_cross_btn">
                          <img src={cross_img} className="check_img" />
                        </MDBBtn>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>

              <MDBRow className="scanner_margin5">
                <MDBCol md="6" className="scanner_margin2">
                  <MDBRow>
                    <MDBCol md="8">
                      <img src={scanner_img6} />
                    </MDBCol>
                    <MDBCol md="4">
                      {voiced.Alexa ? (
                        <MDBBtn className="scanner_check_btn">
                          <img src={check_img} className="check_img" />
                        </MDBBtn>
                      ) : (
                        <MDBBtn className="scanner_cross_btn">
                          <img src={cross_img} className="check_img" />
                        </MDBBtn>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>

                <MDBCol md="6" className="scanner_margin2">
                  <MDBRow>
                    <MDBCol md="8">
                      <img src={scanner_img5} className="scanner_img2" />
                    </MDBCol>
                    <MDBCol md="4">
                      {voiced.Siri ? (
                        <MDBBtn className="scanner_check_btn">
                          <img src={check_img} className="check_img" />
                        </MDBBtn>
                      ) : (
                        <MDBBtn className="scanner_cross_btn">
                          <img src={cross_img} className="check_img" />
                        </MDBBtn>
                      )}
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </div>
          </MDBContainer>
        )}

        <Footer />
        <Signup />
      </div>
    );
  }
}
