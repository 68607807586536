import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from "mdbreact";
import { ChangePasswordUser, get_link_of_forget_password } from "./apis/user";
import ProfileSettingSidebar from "./setting-sidebar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import avtar_img from "./assets/img_avatar.png";
import { get_login_user_info } from "./apis/user";
import swal from "sweetalert";
import { secure_pin } from "../config";

export default class profileUser extends Component {
  state = {
    user_info: {},
    first_name: "",
    last_name: "",
    user_image: "",
    email: "",
    role: "",
    vali: false,
  };
  componentDidMount = () => {
    try {
      let data = { secure_pin, user_id: localStorage.getItem("UserId") };
      get_login_user_info(data)
        .then((res) => {
          console.log("user info", res.data);
          console.log("user info", res.data.users_login[0]);
          console.log("user info", res.data.users_login[0].first_name);
          console.log("user info", res.data);
          if (res.data) {
            this.setState({
              user_info: res.data.users_login,
              first_name: res.data.users_login[0].first_name,
              last_name: res.data.users_login[0].last_name,
              email: res.data.users_login[0].email_id,
              user_image: res.data.users_login[0].profile_image,
              role: res.data.users_login[0].role,
              loading_info: false,
              loading_image: false,
            });
            console.log("user info", this.state.user_info);
            console.log("user info", this.state.first_name);
          } else {
            this.setState({ loading_info: false, loading_image: false });
          }
        })
        .catch((err) => {
          console.log("user info err", err);
          this.setState({ loading_info: false, loading_image: false });
        });
    } catch (e) {}
  };
  changePassword = () => {
    try {
      // let userEmail = localStorage.getItem("UserEmail");
      // const data = {
      //     secure_pin,
      //   email_id: userEmail
      // };

      // if (userEmail) {
      //   // Axios.post(
      //   //   "https://cors-anywhere.herokuapp.com/https://dashify.biz/account/get-link-of-forget-password",
      //   //   data
      //   // )
      //   get_link_of_forget_password(data)
      //     .then(res => {
      //       console.log(res);
      //       swal("Reset Password link sent to your Mail");
      //     })
      //     .catch(res => {
      //       console.log("error in forgot", res);
      //       swal("something went wront");
      //     });

      // }
      let { password, oldPassword, confirmPassword } = this.state;
      if (password && oldPassword && confirmPassword) {
        const data = {
          secure_pin,
          user_id: localStorage.getItem("UserId"),
          old_password: this.state.oldPassword,
          new_password: this.state.password,
        };
        ChangePasswordUser(data)
          .then((res) => {
            console.log(res);
            swal("Password Updated");
          })
          .catch((res) => {
            console.log("error in forgot", res);
            swal("something went wront");
          });
      } else {
        this.setState({ vali: true });
      }
    } catch (e) {}
  };
  render() {
    let userEmail = localStorage.getItem("UserEmail");
    console.log(this.state);
    return (
      <div className="setting-14" style={{ padding: "0px 15px" }}>
        <div className="row ">
          <div className="col-md-4 avatar  ">
            <img
              src={
                this.state.user_info && this.state.user_image
                  ? "https://dashify.biz/Api/assets/upload/images/profile-type-image/" +
                    this.state.user_image
                  : avtar_img
              }
              alt=""
            />
            {/* <img src={avtar_img} alt="" /> */}

            <p>
              {this.state.first_name} {this.state.last_name}
            </p>
          </div>

          <div className="col-md-8 ">
            <div className="form-group row form_gap">
              <label for="inputEmail3" className="col-sm-4 col-form-label">
                Email:
              </label>
              <div className="col-sm-8">
                <input
                  type="email"
                  className="form-control"
                  id="inputEmail3"
                  value={this.state.email}
                  readOnly
                />
              </div>
            </div>
            <div className="form-group row">
              <label for="inputPassword3" className="col-sm-4 col-form-label">
                Password:
              </label>
              <div className="col-sm-8">
                <div className="changes-style mypro_content">
                  <a
                    //onClick={this.changePassword}
                    data-toggle="modal"
                    data-target="#myModal1"
                  >
                    Change
                  </a>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label for="inputPassword3" className="col-sm-4 col-form-label">
                Role:
              </label>
              <div className="col-sm-8 changes-style mypro_content">
                {this.state.role}
              </div>
            </div>
            {/* <div className="save_gap">
                    <button type="submit" className="user_save0">
                      Save
                    </button>
                  </div> */}
          </div>
        </div>

        <div class="modal fade" id="myModal1" role="dialog">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                {/* <button type="button" class="close" data-dismiss="modal">&times;</button> */}
                <div className="ap_heading">Change Password</div>
                <button
                  type="button"
                  className="modal_header_icon"
                  data-dismiss="modal"
                  style={{ color: "#5d80e2", fontSize: "25px", padding: "0px" }}
                >
                  &times;
                </button>
              </div>

              <div class="modal-body">
                <div className="form-group row form_gap">
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Old Password:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      className="form-control"
                      id="inputEmail3"
                      value={this.state.oldPassword}
                      placeholder="*******"
                      onChange={(e) =>
                        this.setState({ oldPassword: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row form_gap">
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Password:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      className="form-control"
                      id="inputEmail3"
                      value={this.state.password}
                      placeholder="*******"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row form_gap">
                  <label for="inputEmail3" className="col-sm-4 col-form-label">
                    Confirm Password:
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      className="form-control"
                      id="inputEmail3"
                      value={this.state.confirmPassword}
                      placeholder="*******"
                      onChange={(e) =>
                        this.setState({ confirmPassword: e.target.value })
                      }
                    />
                  </div>
                </div>

                <button className="pay_last_btn " onClick={this.changePassword}>
                  Save
                </button>
                {this.state.vali ? (
                  <div className="err_msg">*All Fields are required</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
