import Axios from "axios";
import { API } from "../../config";

export const Success_Payment = (data) => {
  return Axios.post(`${API}/admin/web_api/membership_order`, data);
};

export const Get_All_Membership = (data) => {
  return Axios.post(`${API}/admin/web_api/get_all_membership`, data);
};
