import React, { Component } from "react";
import { all_connection_of_one_location } from "../apis/social_platforms";
import {optimization_score} from '../apis/social_media'
import {
  location_by_id,
  business_categories,
  business_states
} from "../apis/location";

import {all_faq_by_location_id,delete_faq,} from "../apis/voice";
import GoogleLogin from "react-google-login";
import Spinner from "../common/Spinner";
import { MDBRow, MDBCol, MDBBtn, MDBCard, MDBContainer } from "mdbreact";

import vl_img7 from "../assets/vl_img7.png";
import vl_img8 from "../assets/vl_img8.png";
import vl_img9 from "../assets/vl_img9.png";
import vl_img11 from "../assets/vl_img11.png";
import attachment from "../assets/attachment.png";
import swal from "sweetalert";
import NewFaq from "./newFaq";
import UpdateFaq from "./updateFaq";
import { secure_pin } from "../../config";
import { Link } from "react-router-dom";
const DjangoConfig = {
  headers: { Authorization: "Token " + localStorage.getItem("UserToken") }
};


var ClientID="759599444436-5litbq8gav4ku8sj01o00uh6lsk8ebr0.apps.googleusercontent.com";
var ClientSecret="uhXFruVoWQNiUgxm3eZEL6bN";

export default class VoiceListing extends Component {
  state = {
    loader: true,
    logo: "",
    state: "",
    category: "",
    name: "",
    address: "",
    phone: "",
    about: "",
    city: "",
    postalCode: "",

    new: false,
    allFaq: [],

    update: false,
    faqid: "",

    htmlc: "",
    javac: "",
    allListings: [],
    googleLoggedIn: false,
    yelpIsLoggedIn: false,
    foursquareIsLoggedIn: false,
    appleIsLoggedIn: false,
    yelpAlexa: false,
    fourBixby: false,
    googleOptimized: false,
    appleOptimized: false
  };

  editFaq = id => {
    try{
    this.setState({ faqid: id, update: true });
  }catch(e){}
  };

  deleteFaq = nameid => {
    try{
    swal({
      title: "Are You Sure TO Delete This FAQ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
    var data = {
      secure_pin,
      user_id: localStorage.getItem("UserId"),
      faqid: nameid
    };

   
    delete_faq(data).then(resp => {
      var datal = {
        secure_pin,
        user_id: localStorage.getItem("UserId"),
        location_id: this.props.match.params.locationId
      };
  
      all_faq_by_location_id(datal)
        .then(resp => {
          console.log("all faq0", resp);
          console.log("all faq1", resp.data);
          this.setState({ allFaq: resp.data.faq_list });
        })
        .catch(err => {
          console.log("err in getallFaq", err);
        });
    });}
  })
    
    }catch(e){}};

  submitCancel = e => {
    try{
    this.setState({ new: false });
  }catch(e){}
  };
  updateCancel = e => {
    try{
    this.setState({ update: false });
  }catch(e){}
  };
  getNewAllFaq = () => {
    try{
    // all_faq(DjangoConfig)
    //   .then(resp => {
    //     console.log("AllFaq", resp.data.all_faqs);
    //     this.setState({
    //       allFaq: resp.data.all_faqs
    //     });
    //   })
    //   .catch(err => {
    //     console.log("err in getallFaq", err);
    //   });

    var datal = {
      secure_pin,
      user_id: localStorage.getItem("UserId"),
      location_id: this.props.match.params.locationId
    };

    all_faq_by_location_id(datal)
      .then(resp => {
        console.log("all faq0", resp);
        console.log("all faq1", resp.data);
        this.setState({ allFaq: resp.data.faq_list });
      })
      .catch(err => {
        console.log("err in getallFaq", err);
      });
    }catch(e){}};

  addFaq = e => {
    try{
    this.setState({ new: true });
    console.log("add");
  }catch(e){}};
  installWedget = e => {
    try{
    var dynadiv = "";
    var dynaJs = "";
    if (this.state.allFaq.length > 0) {
      this.state.allFaq.map(r => {
        dynadiv +=
          "<div class='faq'> <div class='faq-question'> <span class='faq-question-label'>Q.</span> <span class='faq-value'>" +
          r.que +
          "</span> </div><div> <span class='faq-answer-label'>A.</span> <span class='faq-value'>" +
          r.ans +
          "</span> </div></div>";
        dynaJs +=
          '{"@type":"Question","name":"' +
          r.que +
          '","acceptedAnswer":{"@type":"Answer","text":"' +
          r.ans +
          '"}},';
      });

      this.setState({
        htmlc:
          "<style>body{background: #e9ecee; padding: 2%;}​ .faq{margin-top: 2%;}.faq-question{background: whitesmoke font-weight: bold;}​ .faq-question-label{font-size: large;}​ .faq-answer-label{font-size: large;}.faq-value{font-size: large; margin-left: 1%;}</style> " +
          dynadiv,

        javac:
          '<script type="application/ld+json">{"@context":"http://schema.org","@type":"FAQPage","mainEntity":[' +
          dynaJs +
          "]]}</script>"
      });
    }
  }catch(e){} };

  componentDidMount() {
    try{
    const data = {
      secure_pin,
      location_id: this.props.match.params.locationId
    };
    
console.log("location44",data)
    if(this.props.match.params.locationId !=="null"){
    location_by_id(data).then(resp => {
      console.log("hi");
      this.setState({ state: "Loading....", category:  resp.data.location_details[0].bussiness_cate});
      const data1={secure_pin,countryid:resp.data.location_details[0].country}
      business_states(data1).then(resp1 => {
        resp1.data.all_states.map((s, i) =>
        s.id == resp.data.location_details[0].state
            ? this.setState({ state: s.name })
            : ""
        );
      }).catch(resp => {
        console.log(resp);
       
      });

      business_categories(data).then(resp1 => {
        resp1.data.bussiness_category_array.map((b, i) =>
          b.id == resp.data.location_details[0].bussiness_cate
            ? this.setState({ category: b.name })
            : ""
        );
      }).catch(resp => {
        console.log(resp);
       
      });

      console.log("bbb",resp.data);
      this.setState({
        location: resp.data.location_details[0],
        name: resp.data.location_details[0].location_name,

        address: resp.data.location_details[0].address1,

        phone: resp.data.location_details[0].phone_no,

        about: resp.data.location_details[0].about_bussiness,

        city: resp.data.location_details[0].city,
        postalCode: resp.data.location_details[0].zipcode,
        logo: resp.data.location_details[0].bussiness_logo,
        cover: resp.data.location_details[0].bussiness_cover_image,
        otherImage: resp.data.location_images,

        loader: false
      });
    });
  }
    var datal = {
      secure_pin,
      user_id: localStorage.getItem("UserId"),
      location_id: this.props.match.params.locationId
    };

    all_faq_by_location_id(datal).then(resp => {
      console.log("all faq", resp);
      this.setState({ allFaq: resp.data.faq_list });
    }).catch(resp => {
      console.log(resp);
     
    });

    // const Yelpconfig = {
    //   headers: {
    //     Authorization:
    //       "bearer _1cVnrrkqmG_dwNUdtorVxarkzItJM7AWM700rkRxM7aPdDfxJECcdaN00ADjSkrStF1pX4sdGCspYeSjU7VGkpjWYoMsC2_filBf5d5J5GMRTgXws_W6qusNMhYX3Yx",
    //     "Content-Type": "application/json",
    //     "Access-Control-Allow-Origin": "http://localhost"
    //   }
    // };

    const dataVoice = {
      secure_pin,"user_id":localStorage.getItem("UserId") ,"location_id":localStorage.getItem("locationId")
    };
   
    all_connection_of_one_location(dataVoice, DjangoConfig)
      .then(resp => {
        console.log("get all connections by id s", resp);
        this.setState({ allListings: resp.data.social_media_list });

        if (this.state.allListings) {
          this.state.allListings.map(l => {
            console.log("loop all")
            if (l.connect_type == "Facebook") {
              

              this.setState({
                fbIsLoggedIn: true,
          
              });
            }

            if (l.connect_type === "Google") {
             
              this.setState({
                googleIsLoggedIn: true,
             
              });

             
            }


            if (l.connect_type === "Foursquare") {
              console.log("yes four");
              this.setState({
                foursquareIsLoggedIn: true,
               
              });
            }


            if (l.connect_type === "Yelp") {
              console.log("yes yelp");
              this.setState({
                yelpIsLoggedIn: true,
               
              });
             
            }

            if (l.connect_type == "Apple") {
              console.log("yes Apple");
              this.setState({
                appleIsLoggedIn: true,
               
              });
            }


           
            
          });
        }
      })
      .catch(resp => {
        console.log(resp);
      });

      const dataOpt={
        secure_pin,"user_id":localStorage.getItem("UserId") ,"location_id":localStorage.getItem("locationId")
      
        ,"connect_type":"Yelp"
      };


      optimization_score(dataOpt)
      .then(respon => {
        console.log("voice", respon);
        this.setState({
          VoiceEnabled:respon.data.social_score

        });



        if (respon.data.social_score) {
          respon.data.social_score.map(l => {
            console.log("voise loop all")
           

            if (l.connect_type === "Google") {
             
              this.setState({
                googleOptimized: l.all_status,
             
              });

             
            }


            if (l.connect_type === "Foursquare") {
              console.log("yes four");
              this.setState({
                fourBixby: l.all_status,
               
              });
            }


            if (l.connect_type === "Yelp") {
              console.log("yes yelp");
              this.setState({
                yelpAlexa: l.all_status,
               
              });
             
            }

            if (l.connect_type == "Apple") {
              console.log("yes Apple");
              this.setState({
                appleOptimized: l.all_status,
               
              });
            }


           
            
          });
        }

      })
      .catch(resp => {
        console.log(resp);
      });



    }catch(e){} }

  htmlcopy = e => {
    try{
    e.preventDefault();
    document.querySelector("#htmlcode").select();
    document.execCommand("copy");
  }catch(e){} };

  javacopy = e => {
    try{
    e.preventDefault();
    document.querySelector("#javascriptcode").select();
    document.execCommand("copy");
  }catch(e){}};

  responseErrorGoogle = response => {
    try{
    console.log(response);
    swal("try again");
  }catch(e){}
  };

  responseGoogle = async response => {
    try{
    console.log("google response", response);

    let state = {
      Token: response.accessToken,
      Username: response.profileObj.name,
      Email: response.profileObj.email,
      location_id: this.props.match.params.locationId,
      redirect_to: "/voice-listing"
    };
    this.props.history.push({
      pathname: `/google-connectedaccounts/${encodeURIComponent(
        JSON.stringify(state)
      )}`
    });
  }catch(e){}};

  render() {
    if (this.state.otherImage) {
      var otherIma = this.state.otherImage.map((img, i) => (
        <img src={"https://dashify.biz/Api/assets/upload/images/business-type-image/" +
        img.image
      } className="vl_img" />
      ));
    }
console.log("this.state.allFaq",this.state.allFaq)
    if (this.state.allFaq) {
      var AllFaq = this.state.allFaq.map(r => {
        var nameid = r.id;

        return (
          <div>
            {this.state.update?(
              null
  //           <MDBRow>
              
  //           <MDBCol md='8'  >
  // <MDBRow className='vl_gap1'>
  //   <MDBCol md='3' className="vl_input_head">
  //   Question :
  //   </MDBCol>
  //   <MDBCol md='9' >
  //                    <input
  //                   type="text"
  //                   className="vl_input"
  //                   name="que"
  //                   value={this.state.que}
  //                   onChange={this.handler}
  //                 />
  //                 <div style={{ color: "red" }}>{this.state.que_error}</div>
  //   </MDBCol>
  // </MDBRow>
  // <MDBRow className='vl_gap1'>
  //   <MDBCol md='3' className="vl_input_head ">
  //   Answer :
  //   </MDBCol>
  //   <MDBCol md='9' >
  //   <textarea
  //                    type="text"
  //                    name="ans"
  //                    className="vl_input"
  //                    value={this.state.ans}
  //                    onChange={this.handler}
  //                  />
  //                 <div style={{ color: "red" }}>{this.state.ans_error}</div>
  //   </MDBCol>
  // </MDBRow>
  //           </MDBCol>
           
  //             <MDBCol md="4" className='vl_gap2' >
  //                         <button
  //               // onClick={this.updateFaq(
  //               //   this.state.que,
  //               //   this.state.ans,
  //               //   this.props.faqid
  //               // )}
  //               className="user_btn"
  //             >
  //               Update
  //             </button>
  //             <button onClick={this.updateCancel} className="user_btn">
  //               Cancel
  //             </button>
  //           {/* <div className="warning">{show_err_updatefaq}</div> */}
  //           </MDBCol>
  //           </MDBRow>
           
           ):(<>
              <MDBRow>
              <MDBCol md="7" className="offset-md-1">
                <div className="vl_c3_subhead"> {r.que}</div>
                <div className="vl_contant">{r.ans}</div>
              </MDBCol>
              <MDBCol md="1" >
                <MDBBtn
                  onClick={() => this.editFaq(nameid)}
                  className="vl_btn_c3_edit"
                  style={{marginLeft:'70px'}}
                >
                  Edit
                </MDBBtn>
              </MDBCol>
              <MDBCol md="1" className="offset-md-1">
                <MDBBtn
                  onClick={() => this.deleteFaq(nameid)}
                  className="vl_btn_c3_delete"
                >
                  Delete
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <hr className="voice_hr" />
            </>
            )}
           
          </div>
        );
      });
    }
    return (
      <div>
        {this.props.match.params.locationId != "null" ? (
        
          <div>
            {this.state.loader ? (
          <div className="rightside_title">
            <h1>Voice Listing</h1>
            <Spinner />
          </div>
        ) : (
          <div>
            <MDBContainer>
              <div className="rightside_title">
                <h1>Voice Listing</h1>
              </div>
              <MDBRow className="voice_container">
                <MDBCol sm="12" md="5" lg="5">
                  <MDBRow>
                    <MDBCol sm="5" md="5" lg="5">
                      <img
                        // src={vl_img1}
                        // alt="vl_img1"
                        src={
                          this.state.logo
                            ? "https://dashify.biz/Api/assets/upload/images/business-type-image/" + this.state.logo
                            : require("../../images/Logo2.png")
                        }
                        className="responsive"
                        id="vl_img1"
                      />
                    </MDBCol>
                    <MDBCol sm="7" md="7" lg="7">
                      <div id="vl_heading">{this.state.name}</div>
                      <div className="vl_contant">{this.state.category}</div>
                      <div className="vl_subhead" style={{ marginTop: "10%" }}>
                      Address And Contact
                      </div>
                      <div className="vl_contant">
                        {this.state.address} {this.state.state}{" "}
                        {this.state.postalCode}
                        <br />
                        {this.state.phone ? `Ph : ${this.state.phone}` : ""}
                      </div>
                      <MDBBtn
                        id="vl_btn_edit"
                        href={`/dashboard#/locations/${localStorage.getItem(
                          "locationId"
                        )}/view-location`}
                      >
                        Edit
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </MDBCol>
                <MDBCol xs="12" md="7" lg="7">
                  <div className="vl_subhead" id="buss_desc">
                    Business Description
                  </div>
                  <div className="vl_contant">{this.state.about}</div>
                  <MDBRow>
                    {this.state.otherImage
                      ? this.state.otherImage.map((img, i) => (
                        <img src={"https://dashify.biz/Api/assets/upload/images/business-type-image/" +
                        img.image
                      } className="vl_img" />
                        ))
                      : ""}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBContainer className="voice_container">
              <MDBRow>
                <div className="col-md-12 vl_c2">
                  <ul className="main-listfive">
                    <li>
                      <div className="img-iconbox col-md-3 ">
                        <img
                          src={vl_img7}
                          alt="vl_img"
                          className="vl_c2_icon"
                        />
                      </div>
                      <div className="text-iconbox">
                        <div className="vl_card_head">Google Assistant</div>
                        {/* <MDBBtn className="vl_btn_optimize">Optimize</MDBBtn> */}
                        {this.state.googleIsLoggedIn ? (
                          this.state.googleOptimized ? (
                            <a className="progressb">
                              <i className="zmdi zmdi-check-circle"></i>
                              Optimization in progress
                            </a>
                          ) : (
                            <p className="vl_link" style={{ color: "red" }}>can't optimise</p>
                          )
                        ) : (
                          <GoogleLogin
                            //for localhost
                            // clientId="759599444436-po5k7rhkaqdu55toirpt5c8osaqln6ul.apps.googleusercontent.com"
                            clientId={ClientID}
                            //for server
                            // clientId="759599444436-5litbq8gav4ku8sj01o00uh6lsk8ebr0.apps.googleusercontent.com"
                            // buttonText="Optimize"
                            scope="https://www.googleapis.com/auth/business.manage"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseErrorGoogle}
                            cookiePolicy={"single_host_origin"}
                            className="vl_btn_optimize"
                            buttonText="Optimize"
                            icon={false}
                          />
                        )}
                      </div>
                    </li>

                    <li>
                      <div className="img-iconbox col-md-3">
                        <img
                          src={vl_img8}
                          alt="vl_img"
                          className="vl_c2_icon"
                        />
                      </div>
                      <div className="text-iconbox">
                        <div className="vl_card_head">Amazon Alexa</div>
                        {this.state.yelpIsLoggedIn ? (
                          this.state.yelpAlexa ? (
                            <div className="vl_link">
                              <img
                                src={attachment}
                                alt="attachment_icon"
                                className="attachment"
                              />
                               Optimization in progress
                            </div>
                          ) : (
                            <p className="vl_link" style={{ color: "red" }}>can't optimise</p>
                          )
                        ) : (
                          <MDBBtn className="vl_btn_optimize" href="/yelplogin">
                            Optimize
                          </MDBBtn>
                        )}
                      </div>
                    </li>

                    <li>
                      <div className="img-iconbox col-md-3">
                        <img
                          src={vl_img9}
                          alt="vl_img"
                          className="vl_c2_icon"
                        />
                      </div>
                      <div className="text-iconbox">
                        <div className="vl_card_head">Apple Siri</div>
                        {this.state.appleIsLoggedIn ? (
                          this.state.appleOptimized ? (
                            <div className="vl_link">
                              <img
                                src={attachment}
                                alt="attachment_icon"
                                className="attachment"
                              />
                             Optimization in progress
                            </div>
                          ) : (
                            <p className="vl_link" style={{ color: "red" }}>can't optimise</p>
                          )
                        ) : (
                          <MDBBtn
                            className="vl_btn_optimize"
                            href="/applelogin"
                          >
                            Optimize
                          </MDBBtn>
                        )}
                      </div>
                    </li>
                          {/* Bing */}



                    {/* <li>
                      <div className="img-iconbox col-md-3">
                        <img
                          src={vl_img10}
                          alt="vl_img"
                          className="vl_c2_icon"
                        />
                      </div>
                      <div className="text-iconbox">
                        <div className="vl_card_head">Microsoft Cortana</div>
                        <MDBBtn className="vl_btn_optimize">Optimize</MDBBtn>
                      </div>
                    </li> */}

                    <li>
                      <div className="img-iconbox col-md-3">
                        <img
                          src={vl_img11}
                          alt="vl_img"
                          className="vl_c2_icon"
                        />
                      </div>
                      <div className="text-iconbox">
                        <div className="vl_card_head">Samsung Bixby</div>
                        {this.state.foursquareIsLoggedIn ? (
                          this.state.fourBixby ? (
                            <div className="vl_link">
                              <img
                                src={attachment}
                                alt="attachment_icon"
                                className="attachment"
                              />
                              Optimization in progress
                            </div>
                          ) : (
                            <p className="vl_link" style={{ color: "red" }}>can't optimize</p>
                          )
                        ) : (
                          <MDBBtn
                            className="vl_btn_optimize"
                            href="/foursquarelogin"
                          >
                            Optimize
                          </MDBBtn>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className="voice_container">
              <MDBRow>
                <MDBCol className="col-md-7">
                  <div id="vl_c3_head">Voice FAQs</div>
                  <div id="vl_c3_contant">
                    Add FAQs that will help you to customize your location to
                    appear in voice search{" "}
                  </div>
                </MDBCol>
                <MDBCol className="col-md-5">
                  <MDBBtn onClick={this.addFaq} id="vl_btn_FAQ">
                    + Add FAQs
                  </MDBBtn>
                  <MDBBtn
                    onClick={this.installWedget}
                    data-toggle="modal"
                    data-target="#myModal"
                    id="vl_btn_wedgets"
                  >
                    Install Wedgets
                  </MDBBtn>
                </MDBCol>
              </MDBRow>

              <div id="myModal" className="modal fade" role="dialog">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <form className="needs-validation" noValidate>
                      <div className="modal-header modal_header">
                        <span
                          className="vl_modal_head"
                          style={{ width: "75%" }}
                        >
                          Add FAQs to your website
                        </span>
                        <span>
                          <button
                            type="button"
                            className="close_icon"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </span>
                        {/* <MDBRow style={{ background: "green" }}>
                          <MDBCol
                            md="10"
                            className="vl_modal_head"
                            style={{ background: "red" }}
                          >
                            Add FAQs to your website
                          </MDBCol>
                          <MDBCol md="2" style={{ background: "yellow" }}>
                            <button
                              type="button"
                              className="close_icon"
                              data-dismiss="modal"
                            >
                              &times;
                            </button>
                          </MDBCol>
                        </MDBRow> */}
                      </div>
                      <div className="vl_modal_contant0">
                        Follow the steps below to optimize your website for
                        voice search.
                      </div>
                      <div style={{ padding: "0px 6%" }}>
                        <div className="modal-body">
                          <div className="form-group enterpost">
                            <div className="vl_modal_subhead">Step 01 </div>
                            <div className="vl_modal_contant">
                              Copy the generated html below and paste it into
                              any section of any page on your website. Feel free
                              to modify the CSS if you wish to.
                            </div>
                            <textarea
                              className="codearea"
                              name="htmlcode"
                              id="htmlcode"
                              value={this.state.htmlc}
                              readOnly
                              className="vl_modal_contant"
                            />
                            <button
                              onClick={this.htmlcopy}
                              className="vl_modal_btn"
                            >
                              Copy Code
                            </button>
                            <hr id="vl_hr" />
                            <div className="vl_modal_subhead">Step 02 </div>
                            <div className="vl_modal_contant">
                              Copy the Javascript snippet below and paste it
                              into the head tag of your website.
                            </div>
                            <textarea
                              className="codearea"
                              name="javascriptcode"
                              id="javascriptcode"
                              value={this.state.javac}
                              readOnly
                              className="vl_modal_contant"
                            />

                            <button
                              onClick={this.javacopy}
                              className="vl_modal_btn"
                            >
                              Copy Code
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <hr className="voice_hr" />

              <div className="connect-box">
                {this.state.new ? (
                  <NewFaq
                    locationId={this.props.match.params.locationId}
                    cancel={this.submitCancel}
                    getNewAllFaq={this.getNewAllFaq}
                  />
                ) : (
                  ""
                )}

                {this.state.update ? (
                  <UpdateFaq
                    locationId={this.props.match.params.locationId}
                    faqid={this.state.faqid}
                    cancel={this.updateCancel}
                    allfaq={this.state.allFaq}
                    getNewAllFaq={this.getNewAllFaq}
                  />
                ) : (
                  ""
                )}

                <div>
                {this.state.allFaq.length>0?(AllFaq):       
                        <div className="main_content">
                        {/* <div className="rightside_title">
                          <h1>Review Generation Stats </h1>
                        </div> */}
                        <div className="mb-30">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="analytics-whice mt-30">
                               
                                <div className="promotional-box text-center selectedbx">
                                  <img src={require("../../images/campain-img.jpg")} />
                                  <h2>Sorry!</h2>
                                  <p>
                                    No FAQ Available
                                
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      }
                </div>
              </div>
            </MDBContainer>
          </div>)}
          </div>
        ) : (
          <MDBContainer>
            <div className="rightside_title">
              <h1>Voice Listing</h1>
            </div>
            <div >
                   
            <div className="main_content">
                        {/* <div className="rightside_title">
                          <h1>Review Generation Stats </h1>
                        </div> */}
                        <div className="mb-30">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="analytics-whice mt-30">
                               
                                <div className="promotional-box text-center selectedbx">
                                  <img src={require("../../images/campain-img.jpg")} />
                                  <h2>Sorry!</h2>
                                  <p>
                                  Select Location first
                                
                                  </p>
                                  <Link to='/location-manager'> <button  className="btn">Click Here</button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
            </div>
          </MDBContainer>
        )}
      </div>
    );
  }
}
