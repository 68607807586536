import React from "react";
import axios from "axios";

class FeedbackHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
      baseurl: [],
    };
  }

  componentDidMount() {
    const data = {
      secure_pin: "digimonk",
    };

    try {
      axios
        .post("https://dashify.biz/Api/admin/web_api/get_all_testimonial", data)
        .then((response) => {
          console.log(response.data.baseurl);

          this.setState({
            apiData: response.data.TestimonialData,
            baseurl: response.data.baseurl,
          });
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    let FinalRender;

    if (this.state.apiData) {
      FinalRender = this.state.apiData.map((r) => {
        return (
          <div className="col-md-6">
            <div className="feed-box">
              <div className="feed-img">
                <img src={this.state.baseurl + r.image_file} alt="" />
              </div>
              <div className="feed-text">
                <div className="webox">
                  <p>{r.description}</p>
                  <h5>{r.name}</h5>
                  {/* <span>({r.company})</span> */}
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div class="row">Data Not Found</div>;
    }
    return (
      <div>
        {/* <h1>Testimonial</h1>
        <br/> */}
        <div className="feedback-box">
          <div className="container">
            <h2 className="heading">We Appreciate Our Customer`s Feedbacks!</h2>
            <p className="lead-text">
              Here’s what some of our customers say about our work.
            </p>
            <div className="row top-50">
              {FinalRender}
              {/* 
                  <div className="col-md-12 text-center mt-50">
                    <a href="#" className="many_btn">
                      Read more <img src="" alt="" />
                    </a>
                  </div>
                */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FeedbackHome;
