import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { add_social_account } from "./apis/social_platforms";
import Spinner from "./common/Spinner";
import { MDBCol, MDBRow } from "mdbreact";
import { secure_pin } from "../config";
import swal from "sweetalert";

const DjangoConfig = {
  headers: { Authorization: "Token " + localStorage.getItem("UserToken") },
};

class GoogleConnectedAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isUrl: false,
      loader: true,
      all_pages: [],
      google_props: "",
      accountName: "",
      tokenSave: "",
      locateId: "",
      newBusiness: {},
      newBusinesso: {},
    };
  }

  componentDidMount = async () => {
    console.log(
      "all props",
      JSON.parse(decodeURIComponent(this.props.match.params.state))
    );
    this.setState({
      locateId: JSON.parse(decodeURIComponent(this.props.match.params.state))
        .location_id,
      tokenSave: JSON.parse(decodeURIComponent(this.props.match.params.state))
        .Token,
    });
    await this.setState({
      google_props: JSON.parse(
        decodeURIComponent(this.props.match.params.state)
      ),
    });

    const GoogleConfig = {
      headers: {
        Authorization: "Bearer " + this.state.google_props.Token,
      },
    };

    console.log("google token", GoogleConfig);

    Axios.get(
      "https://mybusiness.googleapis.com/v4/accounts/",
      GoogleConfig
    ).then((res) => {
      console.log(res);
      localStorage.setItem("accountId", res.data.accounts[0].name);
      console.log(res.data.accounts[0].name, "name check");
      this.setState({
        accountName: res.data.accounts[0].name,
      });
      Axios.get(
        "https://mybusiness.googleapis.com/v4/" +
          localStorage.getItem("accountId") +
          "/locations",
        GoogleConfig
      ).then((resp) => {
        console.log("google location from ", resp.data);
        this.setState({
          all_pages: resp.data.locations ? resp.data.locations : [],
          loader: false,
        });
      });
    });
  };
  componentDidUpdate = () => {
    console.log(this.state.locateId, "check locate id");
    console.log(this.state.tokenSave, "tokem sahebe");
    console.log(this.state.newBusiness, "check it");
  };
  handleAgainConnect = () => {
    var data = JSON.stringify({
      secure_pin: "digimonk",
      userId: localStorage.getItem("UserId"),
      locationId: this.state.locateId,
      accountId: this.state.accountName,
      accountToken: this.state.tokenSave,
    });

    var config = {
      method: "post",
      url: "https://dashify.biz/Api/admin/socialmedia_api/createGoogleBussinessPage",
      headers: {
        "Content-Type": "application/json",
        // Cookie: "ci_session=j7u1vepi11dq4uk4sk2te6vpj8n74pig",
      },
      data: data,
    };

    Axios(config)
      .then((response) => {
        // console.log(data, "sending google data");
        console.log(response.data, "again connect");
        const data = response.data;
        console.log(this, "ooooooooo");

        this.setState({
          newBusiness: data,
        });
        console.log(this.state.newBusiness, "pppppppppppp");
      })
      .catch((error) => {
        console.log(error, "error google");
      });
  };

  onSubmit = (index) => (e) => {
    e.preventDefault();
    this.setState({ loader: true });

    const g_data = this.state.google_props;

    const data = {
      // location_id: g_data.location_id,
      // Platform: "Google",
      // Token: g_data.Token,
      // Username: this.state.all_pages[index].locationName,
      // Email: g_data.Email,
      // Password: "",
      // Connect_status: "Connect",
      // Other_info: this.state.all_pages[index].name,

      secure_pin,
      user_id: localStorage.getItem("UserId"),
      location_id: g_data.location_id,
      google_id: g_data.googleIdf ? g_data.googleIdf : "",
      token: g_data.Token,
      name: this.state.all_pages[index].locationName,
      email_id: g_data.Email,
      image_url: g_data.googleImgUrl ? g_data.googleImgUrl : "",
      connect_type: "Google",
      path_name: this.state.all_pages[index].name,
      refresh_token: g_data.Refresh,
    };
    console.log("googleRa", data);

    add_social_account(data, DjangoConfig)
      .then((resp) => {
        console.log("google location response", resp.data);
        this.setState({ isUrl: true, loading: false });
      })
      .catch((resp) => {
        console.log(resp);
        this.setState({ loading: false });
      });
  };

  onSubmit2 = (index) => (e) => {
    e.preventDefault();
    const g_data = this.state.google_props;

    let isError = false;

    this.setState({
      username_error: "",
      password_error: "",
      url_error: "",
      wrong: "",
    });

    if (this.state.username == "") {
      this.setState({
        username_error: "Enter your Email",
      });
      isError = true;
    }
    if (this.state.password == "") {
      this.setState({ password_error: "Enter your password" });
      isError = true;
    }
    if (this.state.url == "") {
      this.setState({ url_error: "Enter Url" });
      isError = true;
    }

    if (!isError) {
      this.setState({ loading: true });

      const DjangoConfig = {
        headers: {
          Authorization: "Token " + localStorage.getItem("UserToken"),
        },
      };

      // const fourUrl = this.state.url.split("/")[5];
      // localStorage.setItem("fourUrl", fourUrl);
      // Axios.get(
      //   "https://cors-anywhere.herokuapp.com/https://api.foursquare.com/v2/venues/" +
      //     this.state.url.split("/")[5] +
      //     "?client_id=44RU2431YG02H4E00RQTLKEUKIKINQSFO2JBHII2WHH32PXZ&client_secret=FWV2WOL40MQ5M1YZ5E2TKUWIQ4WYZ1QUJXOQ24VGRSXFA3IY&v=20180323"
      // )
      // .then((res) => {
      //   console.log("citysearch resp", res);
      //   if (res.data && res.data.response && res.data.response.venue) {
      const data = {
        // location_id: localStorage.getItem("locationId"),
        // Platform: "Foursquare",
        // Token: "",
        // Username: res.data.response.venue.name,
        // Email: this.state.username,
        // Password: this.state.password,
        // Connect_status: "Connect",
        // Other_info: "{'URL':" + this.state.url + ",'data':''}"

        secure_pin,
        user_id: localStorage.getItem("UserId"),
        location_id: g_data.location_id,
        google_id: g_data.googleIdf ? g_data.googleIdf : "",
        token: g_data.Token,
        name: this.state.all_pages[index].locationName,
        email_id: g_data.Email,
        image_url: g_data.googleImgUrl ? g_data.googleImgUrl : "",
        connect_type: "Google",
        path_name: this.state.all_pages[index].name,
        refresh_token: g_data.Refresh,
      };

      // add_social_account(data)
      add_social_account(data, DjangoConfig)
        .then((resp) => {
          console.log(resp);
          this.setState({ isUrl: true, loading: false });
          swal("Successfully Connected");
        })
        .catch((resp) => {
          swal("Invalid Username Or Password");
          console.log(resp, "CHECK DATA");
          this.setState({
            wrong: "Invalid or Not authorised",
            loading: false,
          });
        });
    } else {
      swal("Invalid urlp");
      this.setState({ loading: false });
    }
    // })
    //     .catch((res) => {
    //       swal("Invalid Username Or Password");
    //       console.log(res.response, "check it");
    //       this.setState({ loading: false });
    //     });
    // }
  };

  render() {
    var link;
    if (this.state.isUrl) {
      return (
        <Redirect
          to={
            "/locations/" +
            localStorage.getItem("locationId") +
            this.state.google_props.redirect_to
          }
        />
      );
    }

    const allPages = this.state.all_pages.map((p, i) => {
      return (
        <form onSubmit={this.onSubmit(i)}>
          <div key={p.id}>
            <hr />
            <MDBRow>
              <MDBCol md="3" className="connect_table_contant">
                {p.locationName}
              </MDBCol>
              <MDBCol md="2" className="connect_table_contant">
                {p.primaryCategory.displayName}
              </MDBCol>
              <MDBCol md="5" className="connect_table_contant">
                {p.address ? (
                  <div>
                    {p.address.addressLines.map((data) => data)},
                    {p.address.locality},{p.address.administrativeArea},
                    {p.address.postalCode}
                  </div>
                ) : (
                  "-"
                )}

                {p.primaryPhone ? (
                  <div>
                    {" "}
                    <br /> <b>Call:</b> {p.primaryPhone}{" "}
                  </div>
                ) : (
                  ""
                )}
              </MDBCol>
              <MDBCol md="2" className="action" style={{ marginTop: "11px" }}>
                <button type="submit">Connect</button>
              </MDBCol>
            </MDBRow>
          </div>
        </form>
      );
    });

    return (
      <div>
        {this.state.loader ? (
          <div>
            <MDBRow>
              <MDBCol md="1">
                <img
                  src={require("../images/google.png")}
                  alt="Google"
                  style={{ height: "40px", width: "40px", marginTop: "25px" }}
                />
              </MDBCol>
              <MDBCol md="11">
                <div
                  className="rightside_title"
                  style={{ marginLeft: "-28px" }}
                >
                  <h1>Google Locations</h1>
                </div>
              </MDBCol>
            </MDBRow>
            <Spinner />
          </div>
        ) : this.state.all_pages.length == 0 ? (
          <>
            {Object.keys(this.state.newBusiness).length ? (
              ""
            ) : (
              <>
                <h4 className="connect_msg">
                  No Google Business Account To Connect
                </h4>
                <div className="new-google-div ">
                  <MDBRow className="mt-2">
                    <MDBCol md="6" className="offset-md-3 text-center">
                      <h3>
                        Are you Sure you want to connect Google business listing
                        Again
                      </h3>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="mt-3">
                    <MDBCol md="4" className="offset-md-3 text-center">
                      <button
                        className="pay_last_btn"
                        onClick={this.handleAgainConnect}
                      >
                        Yes
                      </button>
                      <button className="pay_last_btn mr-5">No</button>
                    </MDBCol>
                  </MDBRow>
                </div>
              </>
            )}
            {Object.keys(this.state.newBusiness).length ? (
              <>
                <form onSubmit={this.onSubmit2}>
                  <div key={this.state.newBusiness.id}>
                    <hr />
                    <MDBRow style={{ background: "#ffffff", margin: "0px" }}>
                      <MDBCol md="4" className="connect_table_heading">
                        Location Name
                      </MDBCol>
                      {/* <MDBCol md="2" className="connect_table_heading">
                        Category
                      </MDBCol> */}
                      <MDBCol md="6" className="connect_table_heading">
                        Address
                      </MDBCol>
                      <MDBCol
                        md="2"
                        className="connect_table_heading"
                        style={{ marginLeft: "-25px" }}
                      >
                        Action
                      </MDBCol>
                    </MDBRow>
                    <MDBRow>
                      <MDBCol md="4" className="connect_table_contant">
                        {this.state.newBusiness.locationName}
                      </MDBCol>
                      {/* <MDBCol md="4" className="connect_table_contant">
                        {this.state.newBusiness.primaryCategory.displayName}
                      </MDBCol> */}
                      <MDBCol md="6" className="connect_table_contant">
                        {this.state.newBusiness.address ? (
                          <div>
                            {this.state.newBusiness.address.addressLines.map(
                              (data) => data
                            )}
                            ,{this.state.newBusiness.address.locality},
                            {this.state.newBusiness.address.administrativeArea},
                            {this.state.newBusiness.address.postalCode}
                          </div>
                        ) : (
                          "-"
                        )}

                        {this.state.newBusiness.primaryPhone ? (
                          <div>
                            {" "}
                            <br /> <b>Call:</b>{" "}
                            {this.state.newBusiness.primaryPhone}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                      </MDBCol>
                      <MDBCol
                        md="2"
                        className="action"
                        style={{ marginTop: "11px" }}
                      >
                        <button type="submit">Connect</button>
                      </MDBCol>
                    </MDBRow>
                  </div>
                </form>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          <div>
            <MDBRow>
              <MDBCol md="1">
                <img
                  src={require("../images/google.png")}
                  alt="Google"
                  style={{ height: "40px", width: "40px", marginTop: "25px" }}
                />
              </MDBCol>
              <MDBCol md="11">
                <div
                  className="rightside_title"
                  style={{ marginLeft: "-28px" }}
                >
                  <h1>Google Locations</h1>
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow style={{ background: "#ffffff", margin: "0px" }}>
              <MDBCol md="3" className="connect_table_heading">
                Location Name
              </MDBCol>
              <MDBCol md="2" className="connect_table_heading">
                Category
              </MDBCol>
              <MDBCol md="5" className="connect_table_heading">
                Address
              </MDBCol>
              <MDBCol
                md="2"
                className="connect_table_heading"
                style={{ marginLeft: "-25px" }}
              >
                Action
              </MDBCol>
            </MDBRow>
            <div
              class="scrollbar"
              style={{ height: "300px", marginRight: "0px" }}
            >
              <div>{allPages}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default GoogleConnectedAccounts;
