import React, { Component } from "react";
import Cropper from "./utils/cropper";
import Loader from "react-loader-spinner";
import edit from "./assets/edit.png";
import avtar_img from "./assets/img_avatar.png";
import { get_login_user_info, update_user_image } from "./apis/user";
import swal from "sweetalert";
import { Add_CompanyProfile } from "./apis/agency";
import { secure_pin } from "../config";
import { MDBCol, MDBRow, MDBBtn } from "mdbreact";
import { AssessmentTwoTone } from "@material-ui/icons";
import file_icon from "./assets/file_icon.png";
import edit_icon from "./assets/edit_icon.png";
export default class profileCompany extends Component {
  state = {
    user_info: {},
    company_name: "",
    user_image: "",
    email: "",
    support_email: "",
    company_logo: null,
    fav_icon: null,
    show_crop_function: false,
    loading_image: true,
  };
  componentDidMount = () => {
    try {
      let data = { secure_pin, user_id: localStorage.getItem("UserId") };
      get_login_user_info(data)
        .then((res) => {
          console.log("user info", res.data);
          if (res.data) {
            this.setState({
              user_info: res.data.users_login,
              company_name: res.data.users_login[0].company,
              email: res.data.users_login[0].email_id,
              user_image: "",
              support_email:res.data.users_login[0].support_email,
              loading_info: false,
              loading_image: false,
            });
          } else {
            this.setState({ loading_info: false, loading_image: false });
          }
        })
        .catch((err) => {
          console.log("user info err", err);
          this.setState({ loading_info: false, loading_image: false });
        });
    } catch (e) {}
  };
  changeHandler = (event) => {
    try {
      this.setState({ [event.target.name]: event.target.value });

      if (event.target.name == "support_email") {
        this.setState({
          support_email: event.target.value,
        });
      }


    } catch (e) {}
  };

  uploadUserImage = (image) => {
    try {
      let { users_login } = this.state;

      console.log("image", image);

      this.setState({ loading_image: true });
      const data = {
        // username: users_login[0].user ? users_login[0].user.username : "",
        secure_pin,
        user_id: localStorage.getItem("UserId"),
        profile_image: image,
      };
      console.log("imgdata", data);
      update_user_image(data)
        .then((response) => {
          console.log("getting image0", response);
          let data2 = { user_id: localStorage.getItem("UserId"), secure_pin };
          get_login_user_info(data2)
            .then((res) => {
              console.log("ll88", res);
              if (res.data) {
                console.log(
                  "getting image",
                  res.data.users_login[0].profile_image
                );
                this.setState({
                  user_image: res.data.users_login[0].profile_image,
                  loading_image: false,
                  show_crop_function: false,
                });
                console.log("getting image1", this.state.user_image);
                // window.location.reload(false)
              } else {
                this.setState({
                  loading_image: false,
                  show_crop_function: false,
                });
                swal("try again0");
              }
            })
            .catch((err) => {
              this.setState({
                loading_image: false,
                show_crop_function: false,
              });
              swal("try again1");
            });
        })
        .catch((res) => {
          this.setState({ loading_image: false, show_crop_function: false });
          swal("try again2");
        });
    } catch (e) {}
  };

  show_crop_function = () => {
    try {
      this.setState({
        show_crop_function: true,
      });
    } catch (e) {}
  };

  submit = (e) => {
    const data = {
      secure_pin,
      user_id: localStorage.getItem("UserId"),

      support_email: this.state.support_email,

      logo: "base64image",
      fav: "base64image",
    };

    Add_CompanyProfile(data)
      .then((res) => {
        console.log("ll88", res);
      })

      .catch((err) => {
        this.setState({ loading_image: false, show_crop_function: false });
        swal("try again1");
      });
  };

  render() {
    console.log("cl", this.state);
    let userEmail = localStorage.getItem("UserEmail");
    return (
      <div className="setting-14" style={{ padding: "0px 15px" }}>
        <MDBRow>
          {/* <div className="agencycontant2">Voice Search Assistant:</div> */}

          <MDBCol md="12" className="profileSpacing">
            <MDBRow>
              <MDBCol md="3">
                <div className="profile3">Email:</div>
              </MDBCol>
              <MDBCol md="6">
                <input
                  type="email"
                  className="profile4"
                  placeholder="info@oasismedia.co"
                  value={this.state.email}
                  name="email"
                  style={{ width: "100%" }}
                  // onChange={this.changeHandler}
                  readOnly
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>

          <MDBCol md="12" className="profileSpacing">
            <MDBRow>
              <MDBCol md="3">
                <div className="profile3">Support Email:</div>
              </MDBCol>
              <MDBCol md="6">
                <input
                  type="email"
                  className="profile4"
                  placeholder="info@oasismedia.co"
                  style={{ width: "100%" }}
                  name="support_email"
                  value={this.state.support_email}
                  onChange={this.changeHandler}
                />
                <div className="mt-4">
                  <MDBBtn className="pay_last_btn" onClick={this.submit}>
                    Save
                  </MDBBtn>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBCol>
          {/* <div className="agencycontant2">Company Assets:</div> */}
          {/*             
<MDBRow >
  <MDBCol md='2' >
    {this.state.company_logo?  <img style={{width:'85px',height:'85px'}} src={this.state.company_logo}/>:
    <img style={{width:'85px',height:'85px'}} src={file_icon}/> }
    </MDBCol>
  <MDBCol md='8' className="profile3">
  Logo represens company’s branding on the Platform. Please upload your business logo here.
  </MDBCol>
  <MDBCol md='2'><img style={{width:'50px',height:'50px'}}src={edit_icon}/>
  <input type='file' name='company_logo' className='com_edit_img' onChange={this.changeHandler}  />
  </MDBCol>
</MDBRow>
<MDBRow>
  <MDBCol md='2' style={{textAlign:'center' ,marginTop:'10px'}}><img style={{width:'45px',height:'45px',}} src={file_icon}/> </MDBCol>
  <MDBCol md='8' className="profile3">
  Fav Icon denotes company’s branding on the Web. Please upload a 64x64px (.ico / .jpeg / .png) 
  </MDBCol>
  <MDBCol md='2'>
    {this.state.fav_icon? <img style={{width:'50px',height:'50px'}}src={this.state.fav_icon}/> : 
    <img style={{width:'50px',height:'50px'}}src={edit_icon}/> }
  <input type='file' name='fav_icon' className='com_edit_img'  onChange={this.changeHandler}/> 
  </MDBCol>
</MDBRow>
 */}

          {/*             
<div className="agencycontant2">Workspace:</div>
            <MDBRow >
            <MDBCol md='6'  className="profile3" style={{marginTop:'45px',marginLeft:'-98px'}} >voicesearchassistant.synup.com </MDBCol>
  
            </MDBRow> */}
        </MDBRow>
      </div>

      // <div>
      //      <div className="row setting-14">
      //     <div class="col-md-4 avatar  ">

      //     {this.state.loading_image ? (
      //     <div style={{ textAlign: "center" }}>
      //       <Loader
      //         type="Oval"
      //         color="#00BFFF"
      //         height={30}
      //         width={30}
      //         // timeout={3000} //3 secs
      //       />
      //     </div>
      //   ) : this.state.show_crop_function ? (
      //     <Cropper uploadUserImage={this.uploadUserImage} />
      //   ) : (
      //     <div>
      //         <img
      //         src={
      //           this.state.user_info && this.state.user_info.user_image
      //             ? "https://digimonk.net/dashify-ci/assets/upload/images/profile-type-image/" +
      //               this.state.user_info.user_image
      //             : avtar_img
      //         }
      //         alt=""
      //       />

      //       <div className="get-image">
      //         <img
      //           src={edit}
      //           alt=""
      //           style={{ height: "20px", width: "20px" }}
      //           onClick={this.show_crop_function}
      //         />
      //         {/* <input type="file" onChange={this.show_crop_function} /> */}
      //       </div>
      //     </div>
      //   )}

      //       {/* <img src={avtar_img} alt="" /> */}

      //       <p>
      //         {this.state.company_name}
      //       </p>
      //     </div>

      //     <div className="col-md-8 ">
      //       <div class="form-group row form_gap">
      //         <label for="inputEmail3" class="col-sm-4 col-form-label">
      //           Email:
      //         </label>
      //         <div class="col-sm-8">
      //           <input
      //             type="email"
      //             class="form-control"
      //             id="inputEmail3"
      //             value={this.state.email}
      //             readOnly
      //           />
      //         </div>
      //       </div>

      //       <div class="form-group row">
      //         <label for="inputPassword3" class="col-sm-4 col-form-label">
      //           Support Email:
      //         </label>
      //         <div class="col-sm-8">
      //         <input
      //             type="email"
      //             class="form-control"
      //             id="inputEmail3"
      //             value={this.state.email}
      //             readOnly
      //           />
      //         </div>
      //       </div>

      //       <div className="save_gap">
      //         <button type="submit" class="user_save0">
      //           Save
      //         </button>
      //       </div>
      //     </div>
      //   </div>

      // </div>
    );
  }
}
