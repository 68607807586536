import React, { Component } from "react";
// import '.../css/Listing_management.css'

import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import Footer from "./footer";
import Navbar from "./navbar";
export default class Listing_management extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Navbar />
        <div className="features pt-5 pb-5">
          <MDBContainer>
            <MDBRow>
              <MDBCol size="7">
                <div className="title-listing">
                  <h2 class="contact_heading">Internet Listing Management</h2>
                  <p>
                    Do you know what makes businesses successful in the modern
                    world? Visibility. All these marketing and PR campaigns that
                    you see every day are only because of a sole reason. That
                    reason is visibility.
                  </p>
                  <p>
                    Without visibility or a presence, your brand won’t reach
                    your target audience and many people will not know about
                    your business at all. This is where we come in. Listing your
                    business on search engines, social media platforms, and the
                    digital world is one way to gain higher visibility and
                    something we excel at.
                  </p>
                  <p>
                    Of course, before opting for any service you need to what it
                    is about and what it entails so here are some details.{" "}
                  </p>
                </div>
              </MDBCol>
              <MDBCol size="5">
                <div class="criclboxs"></div>
                <img
                  src={require("../landingpage/img/listing-banner.png")}
                  alt=""
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
        <div className="listing-search pt-5 pb-5">
          <MDBContainer>
            <MDBRow center>
              <MDBCol md="9">
                <div className="text-center mb-3">
                  <h2 class="contact_heading">
                    What is Internet Listing Management?
                  </h2>
                  <p>
                    This is where you list your business on major search engines
                    people use such as Google, Bing, Yelp, etc. As experts in
                    this field, we manage listings for a variety of big
                    companies to help them gain more visibility.
                  </p>
                  <p>
                    However, for a successful business listing, you need to
                    provide us with some basic information about your business
                    such as:
                  </p>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <div className="checkabilty mb-5">
                  <input type="text" placeholder="website URL" />
                  <input type="text" placeholder="Business Name" />
                  <input type="text" placeholder="Address" />
                  <select className="form-control">
                    <option>Business type 1</option>
                    <option>Business type 2</option>
                    <option>Business type 3</option>
                    <option>Business type 4</option>
                    <option>Business type 5</option>
                  </select>
                  <button>Scan now</button>
                </div>
                <div className="text-center">
                  <p>
                    This is some basic information that will help us get started
                    with listing your busines s on search engines.
                  </p>
                </div>
              </MDBCol>
              <MDBCol md="12">
                <h2 className="text-center mb-5 contact_heading">
                  How does it work?
                </h2>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

        <div className="brand12 pb-5 how-it-work-new">
          <div className="how-it-work-top"></div>
          <MDBContainer>
            <MDBRow>
              <MDBCol md="6">
                <div className="how-it-div">
                  <p>
                    However, do provide accurate information as this is how many
                    people will find you in the digital world. We will make sure
                    that your business information is everywhere and is up to
                    date at all times. Any changes in address, contact number,
                    or anything else need to be communicated to us so we can
                    keep your listings up to date.
                  </p>
                </div>
              </MDBCol>
              <MDBCol md="6">
                <div className="how-it-div">
                  <p>
                    The only thing you need to worry about as a business is just
                    providing us with information. We will take care of the rest
                    and make sure that your business listing shows on every
                    major search engine, social media, maps, directories, and a
                    variety of apps.
                  </p>
                </div>
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-5" style={{ marginBottom: "85px" }} center>
              <MDBCol md="7" className="text-center">
                <h2 className="mb-3 contact_heading">
                  Why you need this service for your business?
                </h2>
                <p>
                  So, by now you are probably wondering why you need to hire
                  someone for this job and how can it benefit your business.
                  Well, there are many reasons a business listing can aid the
                  growth of your business.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="6" className="mb-3">
                <div className="service-div">
                  <MDBRow>
                    <MDBCol md="4">
                      <img
                        src={require("../assets/boosts_visibility.png")}
                        alt=""
                      />
                    </MDBCol>
                    <MDBCol md="8">
                      <div className="listing-text">
                        <h3>Boosts visibility </h3>
                        <p>
                          As a professional business listing service, we make
                          sure that your business is ranked high across all
                          search engines. This will ensure that while searching,
                          your business listing will show on top. This is done
                          by the use of links and SEO, in which we are experts.
                        </p>
                        <p>
                          Our team of professionals will make sure that your
                          website and business is ranked high on search engine
                          results. Now, the higher your business name is the
                          more likely it is that people will click that link.
                          This means that many people will now get to know about
                          your business in a way they never did before!
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>

              <MDBCol md="6" className="mb-3">
                <div className="service-div">
                  <MDBRow>
                    <MDBCol md="4">
                      <img
                        src={require("../assets/value_for_money.png")}
                        alt=""
                      />
                    </MDBCol>
                    <MDBCol md="8">
                      <div className="listing-text">
                        <h3>Value for money </h3>
                        <p>
                          Listing your business with us won’t cost you an arm
                          and a leg. We value our customers and their
                          hard-earned money. This is why our charges will
                          provide you with value for your money and the best
                          services with amazing results.
                        </p>
                        <p>
                          Our strategy has proven successful for many businesses
                          that are now an integral part of our company and
                          always utilize our listing services. We leave no stone
                          unturned when it comes to listing your business
                          successfully.
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
              <MDBCol md="6">
                <div className="service-div">
                  <MDBRow>
                    <MDBCol md="4">
                      <img
                        src={require("../assets/boosts_web_traffic.png")}
                        alt=""
                      />
                    </MDBCol>
                    <MDBCol md="8">
                      <div className="listing-text">
                        <h3>Boosts web traffic </h3>
                        <p>
                          The more traffic you have on your website, the more it
                          will lead to sales. Your website is a representation
                          of your business and many people will judge your
                          services through your website. It will be a big factor
                          when it comes to deciding if they want to utilize the
                          services of your business.
                        </p>
                        <p>
                          Our list of directories and listings will ensure that
                          your website and business information has reached a
                          wide audience. Even an audience that you might have
                          had difficulty targeting before, our professionals
                          will make sure that your business is known to them as
                          well.
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>

              <MDBCol md="6">
                <div className="service-div">
                  <MDBRow>
                    <MDBCol md="4">
                      <img src={require("../assets/stay_ahead.png")} alt="" />
                    </MDBCol>
                    <MDBCol md="8">
                      <div className="listing-text">
                        <h3>Stay ahead of the competition </h3>
                        <p>
                          Everything has gotten fiercely competitive now. To be
                          successful as a business, you need to ensure that you
                          are always ahead of the curve. This is done by making
                          sure that you are coming up with a strategic plan to
                          boost the growth and visibility of your business on
                          all fronts.
                        </p>
                        <p>
                          Business listings on the correct platforms are
                          essential to staying ahead and making sure that your
                          business is visible on all fronts. A successful
                          business listing plan will involve staying ahead of
                          competitors and making sure your business has high
                          rankings on every major search engine.
                        </p>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow center style={{ marginTop: "80px" }}>
              <MDBCol md="8" className="mx-auto">
                <h3 className="text-center mb-5">
                  If you want your business to grow easily and organically, then
                  hire our i nternet listing management services now to stay
                  ahead of the competition and make your brand to a wide
                  audience!
                </h3>
                <div className="text-center mt-4">
                  <span>
                    <button className="lm_book_demo">Book A Demo</button>
                  </span>
                  <span>
                    <button className="lm_learn_more">Learn more</button>
                  </span>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <div className="blue-half-circle circle-quarter"></div>
        </div>

        <Footer />
      </>
    );
  }
}
