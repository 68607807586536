import React, { Component } from "react";
import "./Flaticon.css";
import "./App.css";

import MainApp from "./component/main";

import InstagramLogin from "./component/instagramlogin";
import YelpLogin from "./component/yelplogin";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./component/landingpage/home";
import Review_management from "./component/landingpage/Review_management";
import Blog from "./component/landingpage/Blog";
import BlogDetail from "./component/landingpage/blogDetail";
import newsDetail from "./component/landingpage/newsDetail";

import Career from "./component/landingpage/career";
import ContactUs from "./component/landingpage/contactus";
import Support from "./component/landingpage/support";
import Pricing from "./component/landingpage/Pricing";
import How_we_work from "./component/landingpage/How_we_work";
import Login from "./component/landingpage/login";
import Signup from "./component/landingpage/signup";
import Forgot from "./component/landingpage/forgot.js";
import FourSquareLogin from "./component/foursquarelogin";
import DnbLogin from "./component/dnblogin";
import AppleLogin from "./component/applelogin";
import CitySearchLogin from "./component/citysearchlogin";
import HereLogin from "./component/herelogin";
import ZillowLogin from "./component/zillowlogin";
import TomtomLogin from "./component/tomtomlogin";
import HotelLogin from "./component/hotellogin";
import BookingLogin from "./component/bookinglogin";

import ZomatoLogin from "./component/zomatologin";
import AvvoLogin from "./component/avvologin";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import PageNotFound from "./component/page-not-found";
import Scanner from "./component/landingpage/Scanner";
// import EmailConfirmation from "./component/landingpage/email-confirmation";
import PasswordReset from "./component/landingpage/password-reset";
import RedirectCampaign from "./component/RedirectCampaign";
import Analytics from "./component/landingpage/Analytics";
import { Get_All_Membership } from "./component/apis/membership";
import { secure_pin } from "./config";
import Our_team from "./component/landingpage/Our_team";
import Listing_management from "./component/landingpage/Listing_management";
import Ournews from "./component/landingpage/Ournews";
import Term_Condition from "./component/landingpage/Term_Condition";
import Privacy_Policy from "./component/landingpage/Privacy_Policy";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membership: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.myRef.current.scrollTo(0, 0);
    // window.scrollTo(0, 0);
    Get_All_Membership({ secure_pin })
      .then((res) => {
        console.log("member", res);
        this.setState({
          membership: res.data.membershipData,
        });
      })
      .catch((res) => {
        console.log(res);
      });
  }

  render() {
    console.log("app", this.state.membership);
    return (
      <div className="App">
        <div ref={this.myRef}></div>
        {/* <SideBar/> */}

        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home} />

            <Route path="/blog-detail/:id" component={BlogDetail} />
            <Route path="/news-detail/:id" component={newsDetail} />

            <Route
              exact
              path="/:param1/:param2"
              render={(props) => <Home {...props} />}
            />
            <Route exact path="/blog" component={Blog} />

            <Route exact path="/career" component={Career} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/our-team" component={Our_team} />
            <Route excat path="/term-&-condition" component={Term_Condition} />
            <Route excat path="/privacy-policy" component={Privacy_Policy} />

            <Route
              exact
              path="/listing-management"
              component={Listing_management}
            />
            <Route
              exact
              path="/pricing"
              //  component={Pricing}
              render={(props) => (
                <Pricing membership={this.state.membership} flag={true} />
              )}
            />
            <Route exact path="/how-we-work" component={How_we_work} />
            <Route exact path="/scanner/result" component={Scanner} />
            <Route exact path="/scanner/result/:id" component={Scanner} />

            <Route
              exact
              path="/review-management"
              component={Review_management}
            />
            {/* <Route exact path="/Login" component={Login} /> */}

            <Route
              exact
              path="/password-reset/:param1/:param2"
              render={(props) => <PasswordReset {...props} />}
            />

            <Route
              exact
              path="/redirect/campaign/:id"
              component={RedirectCampaign}
            />

            <Route exact path="/Signup" component={Signup} />
            {/* <Route
              exact
              path="/email-confirmation/:username"
              component={EmailConfirmation}
            /> */}
            <Route exact path="/forgot" component={Forgot} />
            <Route exact path="/instagramlogin" component={InstagramLogin} />
            <Route exact path="/yelplogin" component={YelpLogin} />
            <Route exact path="/foursquarelogin" component={FourSquareLogin} />
            <Route exact path="/dnblogin" component={DnbLogin} />
            <Route exact path="/applelogin" component={AppleLogin} />
            <Route exact path="/citysearchlogin" component={CitySearchLogin} />
            <Route exact path="/herelogin" component={HereLogin} />
            <Route exact path="/zillowlogin" component={ZillowLogin} />
            <Route exact path="/tomtomlogin" component={TomtomLogin} />
            <Route exact path="/hotellogin" component={HotelLogin} />
            <Route exact path="/bookinglogin" component={BookingLogin} />

            <Route exact path="/zomatologin" component={ZomatoLogin} />

            <Route
              path="/avvologin"
              render={(props) => <AvvoLogin {...props} />}
            />

            <Route exact path="/linkedin" component={LinkedInPopUp} />

            <Route
              exact
              path="/dashboard"
              render={(props) => <MainApp membership={this.state.membership} />}
              // component={MainApp}
            />
            <Route path="/analytics" exact component={Analytics} />
            <Route path="/our-news" exact component={Ournews} />

            <Route path="/" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
