import React, { Component } from "react";
import { MDBRow, MDBCol, MDBContainer, MDBBtn } from "mdbreact";
import paypal from "./assets/paypal.png";
import mastercard from "./assets/mastercard.png";
import ProfileSettingSidebar from "./setting-sidebar";
import SettingCardUpdate from "./setting-cardUpdate";
import { card_billing } from "./apis/user";
import { secure_pin } from "../config";
import Pricing from "./landingpage/Pricing";

export default class SettingBilling extends Component {
  state = {
    updateCard: false,
    cardnum: "",
    expiry: "",
    name: "",
    CVV: "",
    address: "",
    city: "",
    country: "",
    zipcode: "",
  };
  changeHandler = (event) => {
    try {
      this.setState({ [event.target.name]: event.target.value });
    } catch (e) {}
  };
  cardUpdate = () => {
    try {
      console.log("states", this.state);
      this.setState({ updateCard: true });
    } catch (e) {}
  };
  updateCardBtn = () => {
    try {
      const data = {
        secure_pin,
        user_id: localStorage.getItem("UserId"),
        card_number: this.state.cardnum,
        name_of_card: this.state.name,
        ex_date: this.state.expiry,
        code: this.state.cvv,
        address: this.state.address,
        city: this.state.city,
        state: "mp",
        country: this.state.country,
        zipcode: this.state.zipcode,
      };
      card_billing(data).then((resp) => {});
    } catch (e) {}
  };
  componentDidMount() {
    try {
      this.setState({ role: this.props.role });
    } catch (e) {}
  }
  componentDidUpdate() {
    try {
      if (this.state.role !== this.props.role)
        this.setState({ role: this.props.role });
    } catch (e) {}
  }
  onClick() {
    window.location.href = "/pricing";
  }
  render() {
    console.log("bill", this.props);
    return (
      <div>
        <MDBContainer>
          <div className="setting-10">
            <h3> Profile Setting</h3>
          </div>

          <MDBRow>
            <MDBCol md="3" className="mb-4">
              <ProfileSettingSidebar role={this.state.role} />
            </MDBCol>
            {/* {this.state.updateCard ? (
              <MDBCol md="9">
                <div className="profile_container">
                  <div className="billing_head"> Update your card</div>
                  <MDBRow>
                    <MDBCol md="6" className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="5">
                          <div className="profile3">Card Number</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile4"
                            placeholder="9999 9999 9999 9999 "
                            name="cardnum"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                    <MDBCol md="6 " className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="5">
                          <div className="profile3">Expiry date</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile5"
                            placeholder="MM/YY"
                            name="expiry"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                    <MDBCol md="6" className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="5">
                          <div className="profile3">Name of card</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile4"
                            placeholder="Jhon Doe"
                            name="name"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                    <MDBCol md="6 " className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="5">
                          <div className="profile3">Security code</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile5"
                            placeholder="CVV"
                            name="cvv"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <div className="billing_head">Billing Address</div>
                  <MDBRow>
                    <MDBCol md="10" className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="3">
                          <div className="profile3">Address</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile4"
                            placeholder="123 St. Jones street"
                            name="address"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                    <MDBCol md="10" className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="3">
                          <div className="profile3">City</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile4"
                            placeholder="Denver"
                            name="city"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                    <MDBCol md="10" className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="3">
                          <div className="profile3">Country</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile4"
                            placeholder="United States Of America"
                            name="country"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>

                    <MDBCol md="10" className="profileSpacing">
                      <MDBRow>
                        <MDBCol md="3">
                          <div className="profile3">ZipCode</div>
                        </MDBCol>
                        <MDBCol md="7">
                          <input
                            className="profile4"
                            placeholder="1323123"
                            name="zipCode"
                            onChange={this.changeHandler}
                          />
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBBtn id="profile_update_card" onClick={this.updateCardBtn}>
                    Update Card
                  </MDBBtn>
                </div>
              </MDBCol>
            ) : ( */}
            <MDBCol md="9">
              {/* <div className="user_container ">
                  <MDBRow>
                    <MDBCol md="3" id="user7">
                      Subscription
                    </MDBCol>
                    <MDBCol md="6" className="user12">
                      Premium Monthly
                    </MDBCol>
                    <MDBCol md="3">
                      <MDBBtn id="user_save">Save</MDBBtn>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="3" id="user_payment_container">
                      <div id="user6">$8</div>
                      <div className="user8">Month</div>
                      <div className="user9">Per Location</div>
                      <div className="user9">Billed Monthly</div>
                    </MDBCol>
                    <MDBCol md="7" id="user10">
                      <div>
                        <span className="user4">Start Date: </span>
                        <span className="user5">30 Jan 2020</span>
                      </div>

                      <div>
                        <span className="user4">Valid Till: </span>
                        <span className="user5">30 Jan 2020</span>
                      </div>

                      <div>
                        <span className="user4">Min. Commitemnt:</span>
                        <span className="user5"> 60 Loсation / Monthly </span>
                      </div>

                      <div>
                        <span className="user4">Currently added: </span>
                        <span className="user5">637 Locations </span>
                      </div>

                      <div>
                        <span className="user4">Next Billing amount: </span>
                        <span className="user5">$5103. 94 On 29 Feb 2020 </span>
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="5">
                      <img src={mastercard} className="dimg" />
                    </MDBCol>
                    <MDBCol md="5">
                      <img src={paypal} className="dimg" />
                    </MDBCol>
                    <MDBCol md="2">
                      <MDBBtn className="add_more" onClick={this.cardUpdate}>
                        +
                      </MDBBtn>
                    </MDBCol>
                  </MDBRow>
                </div>
               */}
              <h3>Current Plan </h3>
              <div className="user_container">
                <div className="current-plan-div-main">
                  <div className="current-data-detail">
                    <div className="current-data-left">
                      <h2>Free</h2>
                      {/* <span className="current-next-date">
                        Next Due Date Sep 11, 2021
                      </span> */}
                    </div>
                    <div className="current-data-right">
                      <h2>$ 0</h2>
                      <span className="per-mnth">per month</span>
                    </div>
                  </div>
                  <div className="current-btn text-right">
                    {/* <MDBBtn className="pricing_enable_btn">Cancel Plan</MDBBtn>
                    &nbsp;&nbsp; */}
                    <MDBBtn
                      className="pricing_enable_btn"
                      onClick={this.onClick}
                    >
                      Upgrade Plan
                    </MDBBtn>
                  </div>
                </div>
              </div>
              {/* <Pricing
                membership={this.props.membership}
                flag={false}
                pricingId={this.props.pricingId}
                userPric={this.props.userPric}
              /> */}
            </MDBCol>
            {/* ) */}
            {/* } */}
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}
