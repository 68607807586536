import React, { Component } from "react";
import Axios from "axios";
import Spinner from "./common/Spinner";
import { all_location } from "./apis/location";
import { Last } from "react-bootstrap/esm/PageItem";
import { MDBCol, MDBRow } from "mdbreact";
import { secure_pin } from "../config";
const DjangoConfig = {
  headers: { Authorization: "Token " + localStorage.getItem("UserToken") },
};

export default class ViewLocations extends Component {
  state = {
    loader: true,
    AllLocations: [],
    currentPage: 1,
    LocationsPerPage: 5,
  };

  componentDidMount() {
    try {
      const DjangoConfig1 = {
        headers: {
          Authorization: "Token " + localStorage.getItem("UserToken"),
        },
      };
      const data = {
        user_id: localStorage.getItem("UserId"),
        secure_pin,
      };
      console.log("data55", data);
      all_location(data)
        .then((res) => {
          console.log("ll", res);
          console.log("loc", res.data.all_location);

          this.setState({ AllLocations: res.data.all_location, loader: false });
        })
        .catch((res) => {
          console.log("error in LocationManager", res);
          this.setState({ loader: false });
        });
    } catch (e) {}
  }

  handleClick = (event) => {
    try {
      console.log("handleClick", event.target.id, event);
      this.setState({
        currentPage: Number(event.target.id),
      });
    } catch (e) {}
  };

  changeItemsPerPage = (event) => {
    try {
      console.log("items", event.target.value);
    } catch (e) {}
  };
  itemsPerPage = (e) => {
    try {
      this.setState({
        LocationsPerPage: e.target.value,
      });
    } catch (e) {}
  };
  pagebottom = () => {
    window.scrollTo(0, 500);
  };
  render() {

    try{
    var link;

    var { AllLocations, currentPage, LocationsPerPage } = this.state;

    // Logic for displaying Locations
    var indexOfLastLocation = currentPage * LocationsPerPage;
    var indexOfFirstLocation = indexOfLastLocation - LocationsPerPage;
    var currentLocations = AllLocations.slice(
      indexOfFirstLocation,
      indexOfLastLocation
    );
  } catch (e) {}


  if(currentLocations){
    var renderLocations = currentLocations.map((loc, index) => {
      return (
        <tr className="listdata " key={loc.id}>
          <td>
            <div className="authordata ">
              <img
                src={
                  loc.bussiness_logo
                    ? "https://dashify.biz/Api/assets/upload/images/business-type-image/" +
                      loc.bussiness_logo
                    : require("../images/Logo2.png")
                }
              />
              <div className="authordatatext">
                <h4>{loc.location_name}</h4>
              </div>
            </div>
          </td>

          <td>
            <div className="address">
              <h4>{loc.address1}</h4>
            </div>
          </td>
          <td>
            <div className="phonenumber">
              <h4>{loc.phone_no}</h4>
            </div>
          </td>
          <td>
            <div className="action">
              <div style={{ display: "none" }}>
                {(link = "dashboard#/locations/" + loc.id + "/view-location")}
              </div>
              <a
                href={link}
                onClick={() => {
                  localStorage.setItem("locationId", loc.id.toString());
                  localStorage.setItem("locationName", loc.location_name);
                  this.props.handleLocationName(loc.location_name);
                }}
              >
                View Listing
              </a>
            </div>
          </td>
        </tr>
      );
    });
  }

    // Logic for displaying page numbers
    var pageNumbers = [];
    for (
      let i = 1;
      i <= Math.ceil(AllLocations.length / LocationsPerPage);
      i++
    ) {
      pageNumbers.push(i);
    }

    if(pageNumbers){
    var renderPageNumbers = pageNumbers.map((number) => {
      return (
        <li>
          <button
            key={number}
            className={
              currentPage == number
                ? "pagination_page_num_selected"
                : "pagination_page_num"
            }
            id={number}
            onClick={this.handleClick}
          >
            {number}
          </button>
        </li>
      );
    });
  }

    

    return (
      <div style={{ marginBottom: "30px" }}>
        {this.state.loader ? (
          <div>
            <MDBRow>
              <MDBCol md="10">
                <div className="rightside_title">
                  <h1>Location Manager</h1>
                </div>
              </MDBCol>
            </MDBRow>
            <div className="rightside_title">
              <Spinner />
            </div>
          </div>
        ) : (
          <div>
            {this.state.AllLocations.length == 0 ? (
              <div>
                <MDBRow>
                  <MDBCol md="10">
                    <div className="rightside_title">
                      <h1>Location Manager</h1>
                    </div>
                  </MDBCol>
                </MDBRow>
                {/* <h4 className='connect_msg'>No Location Added, Please Add A Loaction</h4> */}

                <div className="main_content">
                  {/* <div className="rightside_title">
                          <h1>Review Generation Stats </h1>
                        </div> */}
                  <div className="mb-30">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="analytics-whice mt-30">
                          <div className="promotional-box text-center selectedbx">
                            <img src={require("../images/campain-img.jpg")} />
                            <h2>Sorry!</h2>
                            <p>No Location Added, Please Add A Loaction</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <MDBRow>
                  <MDBCol md="10">
                    <div className="rightside_title">
                      <h1>Location Manager</h1>
                    </div>
                  </MDBCol>
                  <MDBCol md="2">
                    <select
                      onChange={this.itemsPerPage}
                      className="review_select_btn items_select"
                    >
                      <option value="5">5 locations/page</option>
                      <option value="10">10 locations/page</option>
                      <option value="20">20 locations/page</option>
                      <option value="50">50 locations/page</option>
                      <option value="99">99 locations/page</option>
                      <option value="999999">All locations</option>
                    </select>
                  </MDBCol>
                </MDBRow>
                <div className="tablediv table-responsive white-space">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th>Address</th>
                        <th>Phone Number</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{renderLocations}</tbody>
                  </table>

                  {/* <div className="row companyname no-gutters">
                    <div className="col-xs-3">
                      <div className="location_company_name">Company Name</div>
                    </div>
                    <div className="col-xs-3">
                      <div className="location_address">Address</div>
                    </div>
                    <div className="col-xs-3">
                      <div className="location_phone">Phone Number</div>
                    </div>
                    <div className="col-xs-3">
                      <div className="location_action">Action</div>
                    </div>
                  </div>
                  <div
                    class="scrollbar"
                    style={{ height: "380px", marginRight: "0px" }}
                  >
                    <div>{renderLocations}</div>
                  </div> */}
                </div>
                {renderLocations ? (
                  <div className="pagination-main">
                    <div className="pagination">
                      <ul>
                        {currentPage > 1 ? (
                          <li>
                            <button
                              className="prev"
                              key={currentPage}
                              id={currentPage - 1}
                              onClick={this.handleClick}
                            >
                              Previous
                            </button>
                          </li>
                        ) : (
                          <li>
                            <button
                              disabled
                              className="prev"
                              key={currentPage}
                              id={currentPage - 1}
                              onClick={this.handleClick}
                            >
                              Previous
                            </button>
                          </li>
                        )}
                        <li style={{ marginTop: "5px" }}>
                          {renderPageNumbers}
                        </li>
                        {currentPage < pageNumbers.length ? (
                          <li>
                            <button
                              className="next"
                              key={currentPage}
                              id={currentPage + 1}
                              onClick={this.handleClick}
                            >
                              Next
                            </button>
                          </li>
                        ) : (
                          <li>
                            <button
                              disabled
                              className="next"
                              key={currentPage}
                              id={currentPage + 1}
                              onClick={this.handleClick}
                            >
                              Next
                            </button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
