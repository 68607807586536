import React, { Component } from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import axios from "axios";
import arrow from "../assets/arrow.png";
import news1 from "../assets/news1.png";
import news2 from "../assets/news2.png";
import news3 from "../assets/news3.png";
import news4 from "../assets/news4.png";
import { Link, Redirect } from "react-router-dom";

import youtube from "../assets/youtube.png";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
// import Pagination from "react-js-pagination";
import Pagination from "@material-ui/lab/Pagination";
export default class Ournews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      newsData: [],
    };
  }
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const data = {
      secure_pin: "digimonk",
    };
    try {
      axios
        .post("https://dashify.biz/Api/admin/web_api/get_all_news", data)
        .then((response) => {
          this.setState({
            newsData: response.data.newsData,
            baseurl: response.data.baseurl,
          });
          console.log(response, "check news");
        });
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    return (
      <div className="white_background">
        <Navbar />

        <MDBContainer className="container_margin">
          <div className="team_heading">What's new?</div>
          <div className="team_contant">
            <p style={{ width: "60%" }}>
              Find solutions to customer service, general inquiry, product
              problem, business partnership, software upgrade, refund policy and
              any other issues related to Dashify
            </p>
          </div>

          {/* <MDBRow className="news_hr1">
            <MDBCol md="2" className="news_month1">
              August 2020
            </MDBCol>
            <MDBCol md="2" className="news_month2">
              September 2020
            </MDBCol>
            <MDBCol style={{ padding: "0px" }}>
              <img src={arrow} alt="" />
            </MDBCol>
          </MDBRow> */}

          <MDBRow style={{ marginTop: "50px" }}>
            {this.state.newsData.map((item, index) => {
              return (
                <>
                  <MDBCol md="4">
                    <Link to={`news-detail/${item.id}`}>
                      <div className="news-box-showing">
                        <MDBRow key={index}>
                          <MDBCol md="12">
                            {item.image_file != "" ? (
                              <img
                                src={this.state.baseurl + item.image_file}
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                            <div className="sub_news_heading">{item.name}</div>
                            <div className="news_contant">
                              {item.meta_title}
                            </div>
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </Link>
                  </MDBCol>
                </>
              );
            })}
          </MDBRow>
          {/* <div className="pagination_margin">
            <Pagination count={3} shape="rounded" />
          </div> */}
        </MDBContainer>
        {/* <div className="seeshow">
          <div className="container">
            <h2>
              See how your business can deliver verified
              <br />
              answers to searching consumers, helping <br />
              drive discovery and revenue
            </h2>

            <div className="banner-btn pb-5">
              <a href="#" className="book_btn">
                Book A Demo
              </a>
              <a href="#" className="learn_btn">
                Learn more
              </a>
            </div>
          </div>
        </div> */}
        <Footer />
      </div>
    );
  }
}
