import React from "react";
import axios from "axios";
import Moment from "react-moment";
import { Link, Redirect } from "react-router-dom";

import Footer from "./footer";
import Navbar from "./navbar";
import { MDBCol, MDBRow, MDBContainer } from "mdbreact";
import { Row, Col } from "react-bootstrap";

import Pagination from "@material-ui/lab/Pagination";
import arrow from "../assets/arrow.png";
class Blogall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiData: [],
      baseurl: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const data = {
      secure_pin: "digimonk",
    };
    try {
      axios
        .post("https://dashify.biz/Api/admin/blog_api/get_all_blogs", data)
        .then((response) => {
          this.setState({
            apiData: response.data.blogData,
            baseurl: response.data.baseurl,
          });
        });
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    let FinalRender;
    console.log(this.state.apiData, "check blogs");
    if (this.state.apiData) {
      FinalRender = this.state.apiData.map((r) => {
        return (
          <Col md="4" className="mb-3">
            <Link to={`blog-detail/${r.id}`}>
              <div className="blog-vert">
                {r.image_file != "" ? (
                  <img src={this.state.baseurl + r.image_file} alt="" />
                ) : (
                  ""
                )}
                <div className="blog-v-text">
                  <h6>{r.description}</h6>
                  <p>{r.name}</p>
                  <p>
                    <Moment format="MMM YYYY">{r.filter_date}</Moment>, Google
                    launched version 4.6 of its Google My Business (GMB) API
                    for...
                  </p>
                </div>
              </div>
            </Link>
          </Col>
        );
      });
    } else {
      return <div class="row">Data Not Found</div>;
    }
    return (
      <>
        <Navbar />
        <MDBContainer>
          <div className="team_heading">Our Blogs</div>
          <div className="team_contant">
            <p className="col-md-6">
              Find solutions to customer service, general inquiry, product
              problem, business partnership, software upgrade, refund policy and
              any other issues related to Dashify
            </p>
          </div>
        </MDBContainer>
        <div className="white_background">
          <div>
            <div className="container">
              <div className="row top-50">
                {FinalRender}
                <div className="col-md-12 text-center mt-50"></div>
              </div>
            </div>
          </div>
          {/*<MDBContainer className="container_margin">
            <div className="team_heading">Our Blogs</div>
            <div className="team_contant">
              <p className="col-md-6">
                Find solutions to customer service, general inquiry, product
                problem, business partnership, software upgrade, refund policy
                and any other issues related to Dashify
              </p>
            </div>

            <MDBRow className="news_hr1">
              <MDBCol md="2" className="news_month1">
                August 2020
              </MDBCol>
              <MDBCol md="2" className="news_month2">
                September 2020
              </MDBCol>
              <MDBCol style={{ padding: "0px" }}>
                <img src={arrow} alt="" />
              </MDBCol>
            </MDBRow>
            <Row className="mt-4">
              <Col md="8" className="mb-3">
                <div className="blogbox-shadow">
                  <Row xs={1} md={2} lg={2} className="align-items-center">
                    <Col>
                      <img
                        src={require("../landingpage/img/blog-1.png")}
                        alt=""
                      />
                    </Col>
                    <Col>
                      <div className="blog-text-new">
                        <h6>
                          Google’s Latest API Update: Specify Service Items for
                          Your Business
                        </h6>
                        <p>
                          On April 27th 2020, Google launched version 4.6 of its
                          Google My Business (GMB) API for...
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-2.png")} alt="" />
                  <div className="blog-v-text">
                    <h6>4 Reasons Search Is the Most Critical Issue </h6>
                    <p>
                      With 90% of consumers reporting that they use search at
                      every stage of the customer lifecycle...
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-3.png")} alt="" />
                </div>
              </Col>

              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-4.png")} alt="" />
                  <div className="blog-v-text">
                    <h6>Specify Service Items for You</h6>
                    <p>
                      On April 27th 2020, Google launched version 4.6 of its
                      Google My Business (GMB) API for...
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-5.png")} alt="" />
                  <div className="blog-v-text">
                    <h6>
                      4 Reasons Search Is the Most Critical Issue for Your Brand
                      This Year
                    </h6>
                    <p>
                      With 90% of consumers reporting that they use search at
                      every...
                    </p>
                  </div>
                </div>
              </Col>

              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-6.png")} alt="" />
                  <div className="blog-v-text">
                    <h6>
                      4 Reasons Search Is the Most Critical Issue for Your Brand
                      This Year
                    </h6>
                    <p>
                      With 90% of consumers reporting that they use search at
                      every stage of the customer lifecycle...
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-7.png")} alt="" />
                  <div className="blog-v-text">
                    <h6>
                      4 Reasons Search Is the Most Critical Issue for Your Brand
                      This Year
                    </h6>
                    <p>
                      With 90% of consumers reporting that they use search at
                      every stage of the customer lifecycle...
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-8.png")} alt="" />
                </div>
              </Col>

              <Col md="4" className="mb-3">
                <div className="blog-vert">
                  <img src={require("../landingpage/img/blog-9.png")} alt="" />
                  <div className="blog-v-text">
                    <h6>Specify Service Items for You</h6>
                    <p>
                      On April 27th 2020, Google launched version 4.6 of its
                      Google My Business (GMB) API for...
                    </p>
                  </div>
                </div>
              </Col>

              <Col md="8" className="mb-3">
                <div className="blogbox-shadow">
                  <Row xs={1} md={2} lg={2} className="align-items-center">
                    <Col>
                      <img
                        src={require("../landingpage/img/blog-10.png")}
                        alt=""
                      />
                    </Col>
                    <Col>
                      <div className="blog-text-new">
                        <h6>
                          Google’s Latest API Update: Specify Service Items for
                          Your Business
                        </h6>
                        <p>
                          On April 27th 2020, Google launched version 4.6 of its
                          Google My Business (GMB) API for...
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <div className="pagination_margin">
              <Pagination count={3} shape="rounded" />
            </div>
          </MDBContainer>
          <div className="seeshow">
            <div className="container">
              <div className="col-md-8 mx-auto">
                <h2>
                  See how your business can deliver verified answers to
                  searching consumers, helping drive discovery and revenue
                </h2>
              </div>

              <div className="banner-btn pb-5">
                <a href="#" className="book_btn">
                  Book A Demo
                </a>
                <a href="#" className="learn_btn">
                  Learn more
                </a>
              </div>
            </div>
    </div>*/}
          <Footer />
        </div>
      </>
    );
  }
}
export default Blogall;
