import React, { Component } from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import { Container, Row, Col } from "react-bootstrap";

export default class Review_management extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <>
        <Navbar />
        <div className="features">
          <div className="review-main">
            <Container>
              <h2
                className="mb-4 contact_heading"
                style={{ textAlign: "left" }}
              >
                Review Tracking
              </h2>
              <Row>
                <Col md={{ order: "first" }}>
                  <div className="review-img">
                    <img src={require("../assets/review-box.png")} alt="" />
                  </div>
                </Col>

                <Col md={{ order: "last" }}>
                  <div className="review-des">
                    <p>
                      The biggest indicator of the success of your business is
                      the way in which it is perceived by the audience. Opinions
                      matter and this is how many people get to know about your
                      business as well. If the general opinion about your
                      business is not that great, then chances are that you
                      won’t get as many customers as you potentially can. So,
                      then how do you know how your business is perceived by
                      everyone else and how do you try to manage that? Well, for
                      one you need to have an effective system that tracks all
                      reviews of your business.
                    </p>
                    <p>
                      Our state-of-the-art review tracking system is perfect for
                      all the reputation management needs of your business. Our
                      system has integrated the latest technology to provide you
                      with an optimal hassle-free experience.
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4 mb-4">
                <Col md="7">
                  <h2
                    className="mb-4 contact_heading"
                    style={{ textAlign: "left", fontSize: "32px" }}
                  >
                    What is a review tracking system?
                  </h2>
                  <div className="review-des">
                    <p>
                      It is an online system or software that will have all the
                      data of different reviews and customer feedback from a
                      variety of sources. Every site that matters to your
                      business will be included in this system and it will
                      centralize reviews from every single one of these sites.
                    </p>
                    <p>
                      You will be able to see data and trends, and you can
                      utilize that to come up with a successful strategy to
                      manage the reputation and perception of your business.
                    </p>
                    <p> With such a system in place, you can effectively:</p>
                  </div>
                </Col>
                <Col md="5">
                  <div className="review-img">
                    {/* <div className="elps">
                    <img src={require("../assets/ellipse-33.png")} alt="" />
                  </div> */}
                    <img src={require("../assets/review-img2.png")} alt="" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="request-review">
            <Container>
              <Row xs={1} md={2} lg={2}>
                <Col>
                  <div className="request-box">
                    <div className="step1-reques">1</div>
                    <div className="request-text">
                      <h4>Request reviews</h4>
                      <p>
                        You can improve the reputation of your brand by
                        requesting reviews so you can enhance your local
                        visibility. This will help you gain valuable feedback
                        from a variety of sources that you can utilize to boost
                        your presence and local visibility.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="request-box">
                    <div className="step1-reques">2</div>
                    <div className="request-text">
                      <h4> Provide responses</h4>
                      <p>
                        People love it when brands show them that they care
                        about more than just profits. Everyone wants to do
                        business with a brand that goes the extra mile to show
                        their customers that they care.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4 align-items-center" xs={1} md={2} lg={2}>
                <Col>
                  <div className="review-img">
                    <div className="elps1">
                      <img src={require("../assets/ellipse-33.png")} alt="" />
                    </div>
                    <img src={require("../assets/review-img3.png")} alt="" />
                  </div>
                </Col>
                <Col>
                  <div className="review-des">
                    <div className="elps3">
                      <img src={require("../assets/ellipse-29.png")} alt="" />
                    </div>
                    <div className="elps2">
                      <img src={require("../assets/ellipse-29.png")} alt="" />
                    </div>
                    <p>
                      This is why our review tracking system will allow you to
                      respond to reviews and manage your reputation effectively.
                      This will allow you to stay connected and also show
                      customers that you are willing to solve their issues and
                      improve your business.
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4 mb-4">
                <Col md="12">
                  <div className="checkabilty review-meneg">
                    <input
                      type="text"
                      name="website url"
                      placeholder="Website URL"
                    />

                    <input
                      type="text"
                      name="businessName"
                      placeholder="enter business name"
                    />

                    <input type="text" name="address" placeholder="Address" />

                    <select>
                      <option>Business Type</option>
                      <option>Business Type</option>
                      <option>Business Type</option>
                    </select>

                    <button>Scan now</button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className="why-review pt-5">
          <Container className="mb-5">
            <div className="review-title mb-4">
              <Row className="justify-content-md-center">
                <Col md={9}>
                  <h2
                    className="contact_heading"
                    style={{ textAlign: "center" }}
                  >
                    Why Our Review Tracking Is The Best <br /> Choice For You
                  </h2>
                  <p>
                    Now, that you know everything about our review tracking
                    systems and how they work in managing the reputation of your
                    business, you need to know if it is worth it. Many
                    businesses have such systems in place so they can improve
                    their service and reputation. Here is how you can benefit
                    from our review tracking services as well:
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="three-cards how-it-work-new">
          <div class="how-it-work-top"></div>

          <Container>
            <Row xs={1} md={3} lg={3}>
              <Col>
                <div className="review-box how-it-div">
                  <div className="daymend mb-4">
                    <img
                      src={require("../landingpage/img/why-icon-1.png")}
                      alt=""
                    />
                    <h5>Damage control </h5>
                  </div>
                  <p>
                    Some things can get out of our hands. However, we can
                    control the situation to an extent. For example, what if the
                    reason you have low sales is that a competitor is
                    bad-mouthing you on various platforms. How are you going to
                    know and control that damage unless you have an effective
                    system in place?
                  </p>
                  <p>
                    This is just one example but it goes to show how one review
                    can impact your business without you even knowing. Ignorance
                    is not bliss and in this case, you should know how your
                    business is being perceived by the general public.
                  </p>
                  <p>
                    Once you know you can come up with an effective strategy to
                    counter that damage and then control it. This will help you
                    deal with any such situation and then you can take steps to
                    reverse the damage being done.
                  </p>
                </div>
              </Col>
              <Col>
                <div className="review-box how-it-div">
                  <div className="daymend mb-4">
                    <img
                      src={require("../landingpage/img/why-icon-2.png")}
                      alt=""
                    />
                    <h5>Connect with customers </h5>
                  </div>
                  <p>
                    Having such a system is the best way to connect with your
                    customers and interact with them on a more personal level.
                    It will show your customers that you take out the time to
                    read their opinions and take steps to improve your service
                    or products.
                  </p>
                  <p>
                    Customers want to be heard and this is the best platform to
                    let them know that their voices are being heard. This will
                    improve the trust your customers have in you and will
                    improve the relations of your business with its audience.
                  </p>
                  <p>
                    You can even thank customers for their positive reviews so
                    they know that you go out to the way to keep a check on how
                    your business is doing and care about what they have to say.
                  </p>
                </div>
              </Col>

              <Col>
                <div className="review-box how-it-div">
                  <div className="daymend mb-4">
                    <img
                      src={require("../landingpage/img/why-icon-1.png")}
                      alt=""
                    />
                    <h5>Easy to use</h5>
                  </div>
                  <p>
                    Our review tracking system is very user-friendly and you
                    will get all notifications of your reviews in real-time.
                    This will instantly tell you that a review is posted, where
                    is it posted and it will save you a lot of time as you can
                    instantly check and remedy any situation if needs be.{" "}
                  </p>

                  <p>
                    This will make the process of review tracking much easy and
                    simple for you so you don’t have to waste any time
                    navigating.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="listing-bg pt-5">
            <Container>
              <Row>
                <Col md="8" className="mx-auto ">
                  <div className="req text-center ">
                    <h3>
                      The reputation of your business is everything. It can make
                      or break your business. This is why you must keep a track
                      of everything that is happening and how the public
                      perceives your business so you can implement strategies to
                      improve even more.
                    </h3>
                    <p className="mt-5">
                      Our state-of-the-art review tracking system will ensure
                      that you have everything you need to manage the reputation
                      of your business at your fingertips. To know more about
                      this service, contact us now!
                    </p>

                    <div className="elps3">
                      <img src={require("../assets/ellipse-29.png")} alt="" />
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <span>
                      <button className="lm_book_demo">Book A Demo</button>
                    </span>
                    <span>
                      <button className="lm_learn_more">Learn more</button>
                    </span>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
